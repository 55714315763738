import { type StoreRepository } from 'domain/repositories'

export function storeUseCase (repository: StoreRepository): StoreRepository {
  return {
    async login (params) {
      return await repository.login(params)
    },
    async logout () {
      return await repository.logout()
    },
    getStore () {
      return repository.getStore()
    },
    async registerStore (params) {
      return await repository.registerStore(params)
    }
  }
}
