import React from 'react'
import { type CardChallengeProps } from './challengeCard.interface'
import { CardWrapper, ChallengeProgress } from './challengeCard.styles'
import { Button } from '../button'
import { LoggedHomeText } from 'uiConstants'
import { progressColorHelper } from 'ui/helper'
import { gtmCompleteChallenge } from 'ui/pages/loggedHome/loggedHome.datalayer'

export function ChallengeCard (props: CardChallengeProps): JSX.Element {
  const { imageUrl, description, btnText, externalLink, name, dynamicName, target, percentage, progress, progressCopy, isGame, availableOpportunities, totalOpportunities, score } = props
  return (
    <CardWrapper>
      <img className='mb-4 rounded-lg w-full' src={imageUrl} alt='challenge image' />
      {isGame && (
        <>
          Puntaje acumulado: {score}
        </>
      )}
      <ChallengeProgress>
        {isGame
          ? <>Tienes {availableOpportunities} de {totalOpportunities} oportunidades</>
          : <>
        <div className={`flex ${isGame ? 'justify-center' : 'justify-between'} w-full`}>
          <span className='flex-1 block font-bold'>{progressCopy}</span>
          <span className='text-md font-bold md:text-xl text-right' style={{ color: `${progressColorHelper(Number(percentage.substring(0, percentage.length - 1)), true)}` }}>
          {percentage}
          </span>
        </div>
        <div className='bg-white rounded-3xl w-full h-2 my-2 overflow-hidden'>
          <div className={`h-full rounded-3xl ${progressColorHelper(Number(percentage.substring(0, percentage.length - 1)))}`} style={{ width: `${String(percentage.substring(0, percentage.length - 1))}%` }} />
        </div>
        <span>{LoggedHomeText.accumulated} <span className='text-md font-bold'>{progress} de {target} {!isGame ? 'puntos' : ''}</span></span>
          </>}
      </ChallengeProgress>
      <div className='mb-6 text-xs md:text-base' dangerouslySetInnerHTML={ { __html: description || '' } }></div>
      <div className='md:flex md:items-end md:flex-1'>
        <Button disabled={isGame && availableOpportunities <= 0} onAction={() => {
          gtmCompleteChallenge(name, dynamicName)
          window.location.href = externalLink
        }}>
          <span>{btnText}</span>
        </Button>
      </div>
    </CardWrapper>
  )
}
