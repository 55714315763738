import React from 'react'
import { FooterWrapStyle, FooterUlStyle, PostFooterStyle, FooterHomeWrapStyle } from './footer.styles'
import { footerHelper } from './footer.helper'
import { GlobalsTexts } from 'uiConstants'
import { RegisterTermsModal } from 'components'
import LegalImage from '../../assets/images/legal.png'

export function Footer (): JSX.Element {
  const { logo, footerLinks, showTcModal, handleTcModal, handleGtm } = footerHelper()

  return (
    <>
      {!logo
        ? <FooterHomeWrapStyle>
          <ul>
            <li><a href='https://www.bavaria.co/abilegal/aviso-de-privacidad' target='_blank' rel='noopener noreferrer'>Aviso de privacidad</a></li>
            <li><a href='https://www.bavaria.co/abilegal/politica-deproteccion-de-datos-personales-activos-digitales' target='_blank' rel='noopener noreferrer'>Política de protección de datos </a></li>
            <li><a href='https://www.bavaria.co/contactenos' target='_blank' rel='noopener noreferrer'>Contáctenos</a></li>
          </ul>
        </FooterHomeWrapStyle>
        : <>
        <FooterWrapStyle>
          <img src={ logo } alt='logo footer' width='56' className='mb-6 mx-auto'/>
          <FooterUlStyle>
            <li><button onClick={handleTcModal}>{GlobalsTexts.tcLabel}</button></li>
            {footerLinks.map(({ label, link, status }, index) => {
              return (
                <React.Fragment key={index}>
                  {(status === 1 || status === '1') &&
                    <li>
                      <a href={link} target='_blank' rel='noreferrer' onClick={() => { handleGtm(label) }}>
                        {label}
                      </a>
                    </li>
                  }
                </React.Fragment>
              )
            })}
          </FooterUlStyle>
        </FooterWrapStyle>
        <RegisterTermsModal
          showModal={showTcModal}
          handleCloseModal={handleTcModal}
        />
        </>
      }
      <PostFooterStyle>
        <p>{GlobalsTexts.postFooterTextOne}</p>
        <img src={LegalImage} alt='legal-image'/>
        <p>{GlobalsTexts.postFooterTextTwo}</p>
      </PostFooterStyle>
    </>
  )
}
