import { createReducer } from './createReducer'
import { ActionsTypes } from '../actions/actions.types'
import { type AnyAction } from 'redux'
import { storeState } from '../actions/initial.states'

export const store = createReducer(storeState, {
  [ActionsTypes.STORE] (state: Record<string, any>, action: AnyAction) {
    const { payload } = action
    return {
      ...state,
      ...payload
    }
  },
  [ActionsTypes.CLEAR_STORE] () {
    return storeState
  }
})
