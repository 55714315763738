import React from 'react'
import { LoadingWrapStyle } from './loading.styles'
import { loadingHelper } from './loading.helper'

export function Loading (): JSX.Element {
  const { iconColor } = loadingHelper()
  return (
    <LoadingWrapStyle iconColor={iconColor}>
      <div className='wrapper-spinner'>
        <div className='spinner'></div>
      </div>
    </LoadingWrapStyle>
  )
}
