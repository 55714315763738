import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'

export function gtmRankingTab (dynamicName: string, rewardName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Content',
      action: 'Card',
      label: 'Ranking',
      interaction: 'true',
      additionalData: {
        component_name: rewardName,
        campaign_description: dynamicName
      }
    })
  )
}
