import { type SessionRepository } from 'domain/repositories'
import { type SessionEntity } from 'domain/entities'
import { getSessionSelector } from 'data/redux/selectors'
import { clearSessionAction, saveSessionAction, clearStoreInfo, saveAgeGateAction } from 'data/redux/actions'

export function sessionImp (): SessionRepository {
  return {
    getSession (): SessionEntity {
      return getSessionSelector()
    },
    updateDynamicId (id) {
      saveSessionAction({
        dynamicId: id
      })
    },
    clearSession () {
      clearSessionAction()
      clearStoreInfo()
    },
    updateAgeGateStorage (dynamicId) {
      saveAgeGateAction(dynamicId)
    }
  }
}
