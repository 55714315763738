import React from 'react'

export function ChevronRightIcon (): JSX.Element {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.25173 17.6484C9.02677 17.4234 8.90039 17.1182 8.90039 16.8C8.90039 16.4818 9.02677 16.1767 9.25173 15.9516L13.2033 12L9.25173 8.04842C9.03314 7.8221 8.91219 7.51898 8.91493 7.20434C8.91766 6.88971 9.04386 6.58873 9.26635 6.36624C9.48884 6.14375 9.78982 6.01755 10.1045 6.01482C10.4191 6.01208 10.7222 6.13304 10.9485 6.35163L15.7485 11.1516C15.9735 11.3767 16.0999 11.6818 16.0999 12C16.0999 12.3182 15.9735 12.6234 15.7485 12.8484L10.9485 17.6484C10.7235 17.8734 10.4183 17.9998 10.1001 17.9998C9.78194 17.9998 9.47677 17.8734 9.25173 17.6484Z'
        fill='#0C2340'
      />
    </svg>
  )
}
