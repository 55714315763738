import store from '../store'
import { ActionsTypes } from './actions.types'
import { type AnyAction } from 'redux'
import { type StoreEntity } from 'domain/entities'

export function saveStoreInfo (storeData: StoreEntity): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.STORE,
    payload: storeData
  })
}

export function clearStoreInfo (): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.CLEAR_STORE
  })
}
