export function progressTextHelper (progressNumber: number): string {
  if (progressNumber <= 25) {
    return '¡Nunca es tarde!'
  } else if (progressNumber <= 50) {
    return '¡Aún puedes lograrlo!'
  } else if (progressNumber <= 99) {
    return '¡Falta poco!'
  } else {
    return '¡Felicitaciones!'
  }
}

export function secondProgressTextHelper (progressNumber: number): string {
  if (progressNumber <= 25) {
    return 'Cumple tus desafíos'
  } else if (progressNumber <= 50) {
    return 'Completa tus desafíos'
  } else if (progressNumber <= 99) {
    return 'Estás cerca de lograr tu meta'
  } else {
    return 'Lograste tu meta'
  }
}
