import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function WarningDownIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#C31F39', className } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='82'
      height='81'
      fill='none'
      viewBox='0 0 82 81'
      className={ className }
    >
      <path
        fill={ primaryColor }
        d='M44.846 47.472c-.26 2.846-2.07 4.176-3.855 4.176-1.697-.32-3.014-1.044-3.591-3.922l-2.334-26.9c0-2.703 3.288-5.096 6.077-5.096 2.79 0 5.793 2.502 5.793 5.202l-2.09 26.54zm-3.845 7.694a5.054 5.054 0 00-5.054 5.054 5.054 5.054 0 105.054-5.054zm40-14.665c0 22.092-17.906 39.999-40.004 39.999C18.91 80.5.998 62.593.998 40.5c0-22.092 17.91-40 40-40 22.093 0 40.004 17.91 40.004 40zm-7.406 0c0-18.002-14.592-32.592-32.594-32.592-18.002 0-32.592 14.59-32.592 32.592 0 17.998 14.59 32.592 32.592 32.592 18.003 0 32.594-14.593 32.594-32.592z'
      ></path>
    </svg>
  )
}
