import styled from 'styled-components'
import tw from 'twin.macro'

export const CheckboxFieldWrapStyle = styled.div`
  ${tw`pb-5 relative pl-16 inline-block`}
`
export const CheckboxFieldStyle = styled.input`
  ${tw`hidden`}
  &:checked {
    ~ .CheckboxFieldLabelStyle {
      &:after {
        content: '';
      }
    }
  }
`

export const CheckboxFieldLabelStyle = styled.label.attrs({
  className: 'CheckboxFieldLabelStyle'
})`
  ${tw`text-xs items-center flex cursor-pointer md:text-base`}
  height: 48px;
  &:before {
    ${tw`
      bg-white
      block
      relative
      border-solid border-black-60 border rounded-lg
      absolute left-0 top-0
    `}
    content: '';
    height: 48px;
    width: 48px;
  }
  
  &:after {
    ${tw`
      bg-black
      block
      relative
      rounded
      absolute
    `}
    content: none;
    left: 12px;
    top: 12px;
    height: 24px;
    width: 24px;
  }
`
