export enum GlobalsTexts {
  acceptText = 'ACEPTAR',
  closeText = 'CERRAR',
  tcLabel = 'Términos y condiciones',
  logoutLabel = 'Cerrar sesión',
  postFooterTextOne = 'No comparta este contenido con menores de edad',
  postFooterTextTwo = 'Anheuser-Busch InBev © 2023'
}

export enum ErrorMessages {
  onlyAlphabets = 'Solo se permiten letras para este campo.',
  required = 'Campo requerido.',
  email = 'Introduzca un correo electrónico válido.',
  number = 'Solo se permiten números para este campo.',
  beesCode = 'El código Bees es incorrecto o está incompleto'
}
