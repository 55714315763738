import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './ui/routes'
import axios, { type AxiosRequestConfig, type AxiosError } from 'axios'
import { Provider } from 'react-redux'
import Store from './data/redux/store'
import { Toaster } from 'react-hot-toast'
import { useAppViewModel } from 'viewModels'
import { Layout } from './ui/layout/layout'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE

const handleRequestSuccess = (
  request: AxiosRequestConfig
): AxiosRequestConfig => {
  const state = Store.store.getState()
  const { token } = state.session
  const { headers } = request
  if ((headers != null) && (Boolean(token))) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    headers.Authorization = `Bearer ${token}`
  }
  return request
}

function handleRequestError (error: AxiosError): AxiosError {
  return error
}

axios.interceptors.request.use(handleRequestSuccess, handleRequestError)

function App (): JSX.Element {
  useAppViewModel()

  return (
    <Provider store={Store.store}>
      <BrowserRouter>
        <Layout>
          <AppRoutes />
        </Layout>
      </BrowserRouter>
      <Toaster />
    </Provider>
  )
}

export default App
