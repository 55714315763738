import React, { type Dispatch, type SetStateAction } from 'react'
import { TabsWrapper, Tab, ContentTab, TabPagination } from './tabs.styles'
import { type RankingListData, type storeListValue } from 'domain/models'
import { type RewardEntity } from 'domain/entities'
import { ChevronLeftIcon, ChevronRightIcon } from 'assets'
import Pagination from 'rc-pagination'
import { gtmRankingTab } from './tabs.datalayer'

interface TabsProps {
  handleOnClick: (reward: number, page: number) => void
  rankingList: RankingListData
  id: string
  allRewards: RewardEntity[]
  getList: (reward: number, page: number) => void
  toggleState: number
  setInitialPage: Dispatch<SetStateAction<number>>
  initialPage: number
  handleOnChange: (current: number, pageSize: number) => void
  rankingCalculationType: 'daily' | 'static'
  dynamicName: string
}

export function Tabs ({ ...props }: TabsProps): JSX.Element {
  const {
    rankingList,
    id,
    allRewards,
    toggleState,
    initialPage,
    handleOnClick,
    handleOnChange,
    rankingCalculationType,
    dynamicName
  } = props

  const today = new Date()

  return (
    <>
      <>
        <TabsWrapper>
          {allRewards.map((rewardTab: RewardEntity) => {
            const dateCondition = new Date(rewardTab.published_start_date) <= today && today <= new Date(rewardTab.published_end_date)
            const Active = toggleState === rewardTab.id ? 'isActive' : (dateCondition || rankingCalculationType === 'daily') ? '' : 'inactive'
            return <Tab
              key={rewardTab.id}
              data-idx={rewardTab.id}
              className={`${Active}`}
              onClick={(e) => {
                if (dateCondition || rankingCalculationType === 'daily') {
                  handleOnClick(rewardTab.id, Number(initialPage))
                  gtmRankingTab(dynamicName, rewardTab.name)
                }
                e.preventDefault()
                return null
              }}
            >
              {rewardTab.name}
            </Tab>
          })}
        </TabsWrapper>
        <ContentTab className='-ml-4 w-screen md:ml-0 md:w-full'>
          <div className='flex gap-x-4 mt-4 mb-2 ml-2 md:m-0 md:px-1.5 md:py-3'>
            <span className='text-black-40 text-xs md:text-sm'>POSICIÓN</span>
            <span className='text-black-40 text-xs md:text-sm'>CLIENTE</span>
          </div>
          {rankingList?.items?.map((storePos: storeListValue, index) => (
            <div key={`${storePos.id}-${index}`} className='flex items-stretch border-b border-b-black-10'>
              <div
                className={`${
                  id === storePos.id ? 'bg-yellow' : 'bg-black-20'
                } py-3 text-center w-3/12 font-bold flex items-center justify-center md:w-20 text-sm md:text-xl`}
              >
                {storePos.position}
              </div>
              <div
                className={`${
                  id === storePos.id ? 'bg-black-30' : ''
                } w-full py-2 pl-2 flex flex-col justify-center gap-0`}
              >
                <span className='font-bold text-xs md:text-xl'>{storePos.name}</span>
                <span className='text-xs md:text-base'>
                  Código BEES: <span>{storePos.id}</span>
                </span>
              </div>
            </div>
          ))}
        </ContentTab>
        <p className='text-center text-xs text-black-60 my-4'>
          Mostrando {rankingList?.meta?.from}-{rankingList?.meta?.to} de{' '}
          {rankingList?.meta?.total}
        </p>
            <TabPagination>
              <Pagination
                className='flex gap-0.5'
                // defaultCurrent={1}
                current={initialPage}
                onChange={handleOnChange}
                total={rankingList?.meta?.total}
                // pageSize={rankingList ? rankingList.meta.per_page : 0}
                prevIcon={<ChevronLeftIcon />}
                nextIcon={<ChevronRightIcon />}
                hideOnSinglePage={true}
                showLessItems={true}
                jumpPrevIcon={<button>...</button>}
                jumpNextIcon={<button>...</button>}
              />
            </TabPagination>
      </>
    </>
  )
}
