import React from 'react'
import { ModalWrapStyle } from './modal.styles'
import { type ModalProps } from './modal.interfaces'

export function Modal (props: ModalProps): JSX.Element {
  const { children, showModal = false, handleCloseModal, className } = props

  return (
    <>
      {showModal
        ? <ModalWrapStyle className={className}>
          <div>
            {handleCloseModal &&
              <button onClick={handleCloseModal}>
                Close button
              </button>
            }
            { children }
          </div>
        </ModalWrapStyle>
        : null
      }
    </>
  )
}
