import { type SessionEntity } from '../../../domain/entities/session.entity'
import store from '../store'
import { ActionsTypes } from './actions.types'
import { type AnyAction } from 'redux'

export function saveSessionAction (session: SessionEntity): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.SESSION,
    payload: session
  })
}

export function saveAgeGateAction (dynamicId: number): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.AGEGATE_SAVE,
    payload: dynamicId
  })
}

export function clearSessionAction (): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.CLEAR_SESSION
  })
}
