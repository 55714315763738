import styled from 'styled-components'
import tw from 'twin.macro'

export const CardWrapper = styled.div`
  ${tw`bg-white flex flex-col justify-center items-center p-4 pb-8 rounded-lg mb-6`}
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.08);

  @media screen and (min-width: 768px) {
    max-width: 32%;
    flex: 1 32%;
    margin-right: 2%;
    ${tw`justify-start`}
    
    &:nth-child(3n) {
    margin-right: 0;
    }
  }

  > img {
    @media screen and (min-width: 768px) {
      max-height: calc((((100vw - 4%) - 8rem) * 205) / 1240);
      min-height: calc((((100vw - 4%) - 8rem) * 205) / 1240);
    }
    @media screen and (min-width: 1240px) {
      max-height: 205px;
      min-height: 205px;
    }
  }
`

export const ChallengeProgress = styled.div`
 ${tw`bg-black-10 flex flex-col justify-center items-center p-4 rounded-lg w-full my-4`}
`
