import React from 'react'
import { RewardsText } from 'uiConstants'
import { RewardsCardTagStyle, RewardsCardWrapStyle, RewardsTCButtonStyle, RewardsWrapStyle } from './rewards.styles'
import { RankingIcon } from 'assets'
import { rewardsViewModel } from 'viewModels'
import { Loading, RegisterTermsModal } from 'components'

export function Rewards (): JSX.Element {
  const { rewards, loading, showTcModal, handleTcModal } = rewardsViewModel()

  return (
    <>
      <h5 className='mb-6 text-center md:mb-10 md:text-2xl'>{ RewardsText.title }</h5>
      <div>
        <RewardsWrapStyle>
          {rewards.map((reward) => {
            return (
              <RewardsCardWrapStyle key={reward.id}>
                <h6 className='mb-4 text-center md:text-h5-lg'>{reward.name}</h6>
                <img alt={reward.name} className='mb-4 rounded-lg w-full m-auto' src={reward.file_path_image}/>
                <div className='text-xs mb-4 md:text-base' dangerouslySetInnerHTML={ { __html: reward.description } }>
                </div>
                <RewardsCardTagStyle>
                  <div>
                    <span> <RankingIcon /> </span>
                    <span className='text-xs mr-2 ml-3 md:text-base'>{ RewardsText.rankingPositionLabel }</span>
                    <span className='text-xs font-bold md:text-base'>{reward.ranking_initial_position}-{reward.ranking_final_position}</span>
                  </div>
                </RewardsCardTagStyle>
              </RewardsCardWrapStyle>
            )
          })}
        </RewardsWrapStyle>
        <div className='text-center'>
          <RewardsTCButtonStyle onClick={handleTcModal}>
            Ver Términos y condiciones
          </RewardsTCButtonStyle>
        </div>
        <RegisterTermsModal
          showModal={showTcModal}
          handleCloseModal={handleTcModal}
        />
      </div>
      { loading && <Loading /> }
    </>
  )
}
