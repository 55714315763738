import styled from 'styled-components'
import tw from 'twin.macro'

export const NavWrapStyle = styled.div.attrs({
  className: 'NavWrapStyle'
})`
  ${tw`mx-4 px-8 sticky bottom-4 left-0 py-2 mt-6`}
  background-color: ${({ theme }) => theme.color_button};
  border-radius: 32px;
  
  ul {
    list-style: none;
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 0;
    ${tw`flex justify-between items-center`}
  }
  li {
    ${tw`border p-2 rounded-full`}
    border-color: ${({ theme }) => theme.color_button};
  
    &.active {
      border-color: ${({ theme }) => theme.color_icon};
    }
    &.inactive {
      pointer-events: none;
      svg {
        opacity: 0.5
      }
      .label-content {
        opacity: 0.5
      }
    }
  }
  svg {
    path {
      fill: ${({ theme }) => theme.color_icon};
    }
    height: 19px;
    width: 18px;
  }
  .label-content {
    ${tw`hidden`}
  }

  @media screen and (min-width: 768px) {
    ${tw`mt-0 p-0 bg-transparent`}
    
    .label-content {
      ${tw`block`}
    }

    li {
      ${tw`mr-16 border-transparent`}
      a {
        color: ${({ theme }) => theme.color_icon};
        ${tw`flex text-base items-center`}
      }
      &.active {
        border-color:  ${({ theme }) => theme.color_icon};
      }
      svg {
        path {
          fill: ${({ theme }) => theme.color_icon};
        }
        ${tw`mr-3`}
        height: 16px;
        width: 15px;
      }
    }
  }
`
