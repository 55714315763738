/* eslint-disable no-unneeded-ternary */
import { useMemo } from 'react'
import { dynamicImp } from 'data/implementations'
import { dynamicUseCase } from 'domain/useCases'
import { HomeIcon, RankingIcon, RewardIcon } from 'assets'
import { PrivatePath } from 'uiConstants'
import { useNavigation } from 'hooks'
import { type IconsProps } from '../../assets/icons/icons.itenfaces'
import { gtmNavPages } from './navPages.datalayer'

interface NavPagesHelper {
  navConfig: Array<{ icon: (props: IconsProps) => JSX.Element, link: string, status: boolean, name: string, label: string }>
  handleGtm: (name: string) => void
  startDate: Date
  endDate: Date
}

export function navPagesHelper (): NavPagesHelper {
  const { getCurrentPathName } = useNavigation()
  const { getDynamic, getRankingDate } = dynamicUseCase(dynamicImp())
  const { raking_published_end_date: endDate, raking_published_start_date: startDate } = getRankingDate()
  const { name: dynamicNameDescription } = getDynamic()
  const pathName = getCurrentPathName()
  const location = typeof window !== 'undefined' && window.location
  const path = location ? location.pathname.split('/') : []

  const navConfig = useMemo(() => {
    return [
      {
        icon: HomeIcon,
        link: `/${path[1]}/${path[2]}${PrivatePath.loggedHome}`,
        status: `/${path[1]}/${path[2]}${PrivatePath.loggedHome}` === pathName,
        name: 'Home',
        label: 'Inicio'
      },
      {
        icon: RewardIcon,
        link: `/${path[1]}/${path[2]}${PrivatePath.rewards}`,
        status: `/${path[1]}/${path[2]}${PrivatePath.rewards}` === pathName,
        name: 'Premios',
        label: 'Premios'
      },
      {
        icon: RankingIcon,
        link: `/${path[1]}/${path[2]}${PrivatePath.ranking}`,
        status: `/${path[1]}/${path[2]}${PrivatePath.ranking}` === pathName,
        name: 'Ranking',
        label: 'Ranking'
      }
    ]
  }, [path])

  function handleGtm (name: string): void {
    gtmNavPages(dynamicNameDescription, name)
  }

  return {
    navConfig,
    handleGtm,
    startDate,
    endDate
  }
}
