import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function BurgerMenuIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#ffffff' } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='16'
      fill='none'
      viewBox='0 0 18 16'
    >
      <path
        fill={ primaryColor }
        fillRule='evenodd'
        d='M.6 2A1.2 1.2 0 011.8.8h14.4a1.2 1.2 0 110 2.4H1.8A1.2 1.2 0 01.6 2zm0 6a1.2 1.2 0 011.2-1.2h14.4a1.2 1.2 0 110 2.4H1.8A1.2 1.2 0 01.6 8zm0 6a1.2 1.2 0 011.2-1.2h14.4a1.2 1.2 0 110 2.4H1.8A1.2 1.2 0 01.6 14z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}
