import { useState } from 'react'
import { dynamicImp, rankingImp, storeImp, storeRankingImp, sessionImp } from 'data/implementations'
import { type RankingListData } from 'domain/models'
import { rankingUseCase, storeUseCase, rankingStoreUseCase, dynamicUseCase, sessionUseCase } from 'domain/useCases'
import { useCustomEffect } from 'hooks'
import { type RewardEntity } from 'domain/entities'
import { RankingTexts } from 'uiConstants'

interface RankingStore {
  message: string
  ranking: number
  winner: boolean
  reward_name: string
}

export function rankingViewModel (): any {
  const { getStore } = storeUseCase(storeImp())
  const { id } = getStore()
  const { getStoreListData } = rankingUseCase(rankingImp())
  const { getRankingStore } = rankingStoreUseCase(storeRankingImp())
  const { getDynamicHomeData } = dynamicUseCase(dynamicImp())
  const { getSession, clearSession } = sessionUseCase(sessionImp())
  const { dynamicId } = getSession()
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(true)
  const [allRewards, setAllRewards] = useState<RewardEntity[]>([])
  const [rankingList, setRankingList] = useState<RankingListData>()
  const [dynamicName, setDynamicName] = useState<string>('')
  const [rankingCalculationType, setRankingCalculationType] = useState<'daily' | 'static'>('daily')
  const [storeRanking, setStoreRanking] = useState<RankingStore>({
    message: '',
    ranking: 0,
    winner: false,
    reward_name: ''
  })
  const [toggleState, setToggleState] = useState(0)
  const [initialPage, setInitialPage] = useState<number>(1)
  const [loadingTabsDate, setLoadingTabsDate] = useState(true)

  function getList (reward: number, page: number = initialPage): void {
    setLoading(true)
    getStoreListData(Number(reward), Number(page)).then((response) => {
      setLoading(false)
      setRankingList(response.data)
    }).catch(() => {
      setLoading(false)
    })
  }
  function handleOnClick (rewardID: number): void {
    setToggleState(rewardID)
    getList(rewardID, 1)
  }

  useCustomEffect(() => {
    const getRanking = getRankingStore(Number(dynamicId)).then((response) => {
      setStoreRanking(response.data)
    }).catch(() => {
      setStoreRanking({
        message: RankingTexts.messagesErrorService,
        ranking: 0,
        winner: false,
        reward_name: ''
      })
    })

    const getDynamic = getDynamicHomeData(Number(dynamicId)).then(response => {
      const rewards = response.data.rewards
      setDynamicName(response.data.name)
      setRankingCalculationType(response.data.ranking_calculation_type)
      setAllRewards(rewards)
      if (rewards.length) {
        if (response.data.ranking_calculation_type === 'daily') {
          getStoreListData(Number(rewards[0].id), Number(1)).then((response) => {
            setToggleState(Number(rewards[0].id))
            setLoadingTabsDate(false)
            setRankingList(response.data)
          }).catch(() => {
            setLoadingTabsDate(false)
          })
        } else {
          const today = new Date()
          const rewardActive = rewards.filter(reward => new Date(reward.published_start_date) <= today && today <= new Date(reward.published_end_date))
          if (rewardActive.length) {
            getStoreListData(Number(rewardActive[0].id), Number(1)).then((response) => {
              setToggleState(Number(rewardActive[0].id))
              setLoadingTabsDate(false)
              setRankingList(response.data)
            }).catch(() => {
              setLoadingTabsDate(false)
            })
          }
        }
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        clearSession()
      }
    })

    Promise.all([getRanking, getDynamic]).finally(() => {
      setLoading(false)
    })
  }, [])

  function handleOnChange (page?: number): void {
    setInitialPage(Number(page))
    getList(Number(toggleState), Number(page))
  }

  return {
    loading,
    rankingList,
    storeRanking,
    allRewards,
    getList,
    handleOnClick,
    toggleState,
    setInitialPage,
    initialPage,
    id,
    handleOnChange,
    rankingCalculationType,
    loadingTabsDate,
    dynamicName
  }
}
