import React from 'react'
import { useController } from 'react-hook-form'
import { type TextFieldProps } from './textField.interface'
import { TextFieldWrapStyle, TextFieldInputStyle, TextFieldErrorStyle, TextFieldLabelStyle } from './textField.styles'

export function TextField (props: TextFieldProps): JSX.Element {
  const { id, label, errorMessage, name, control } = props
  const { field: { onChange, onBlur, ref, value } } = useController({
    name,
    control,
    defaultValue: ''
  })

  return (
    <TextFieldWrapStyle>
      {label !== null &&
        <TextFieldLabelStyle htmlFor={id}>
          { label }
        </TextFieldLabelStyle>
      }
      <TextFieldInputStyle
        { ...props }
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        value={value}
        id={id}
      />
      {errorMessage !== null &&
        <TextFieldErrorStyle>
          { errorMessage }
        </TextFieldErrorStyle>
      }
    </TextFieldWrapStyle>
  )
}
