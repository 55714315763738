import { useState } from 'react'
import { dynamicImp, sessionImp, storeImp } from 'data/implementations'
import { dynamicUseCase, sessionUseCase, storeUseCase } from 'domain/useCases'
import { useCustomEffect } from 'hooks'
import { type DynamicHomeEntity } from 'domain/entities'
import { type LoggedHomeViewModel } from './viewModel.interfaces'

export function loggedHomeViewModel (): LoggedHomeViewModel {
  const { getDynamicHomeData, getDynamic, getRankingDate } = dynamicUseCase(dynamicImp())
  const { name: dynamicName } = getDynamic()
  const { getStore } = storeUseCase(storeImp())
  const { name, id, client_type_id: clientTypeID } = getStore()
  const { getSession, clearSession } = sessionUseCase(sessionImp())
  const { dynamicId, termsAcceptance } = getSession()
  const { raking_published_end_date: endDate, raking_published_start_date: startDate } = getRankingDate()
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(true)
  const [homeData, setHomeData] = useState<DynamicHomeEntity>()
  const userName = termsAcceptance ? termsAcceptance.previousData?.name : ''

  useCustomEffect(() => {
    getDynamicHomeData(Number(dynamicId)).then((response) => {
      setHomeData(response.data)
      setLoading(false)
    }).catch((error) => {
      if (error.response.status === 401) {
        clearSession()
      }
      setLoading(false)
    })
  }, [])

  return {
    loading,
    homeData,
    name,
    id,
    clientTypeID,
    userName,
    dynamicName,
    startDate,
    endDate
  }
}
