import React from 'react'
import {
  HeaderWrapStyle,
  HeaderMenuMobileStyle,
  HeaderAnonymousWrapStyle,
  HeaderAuthWrapStyle,
  HeaderWrapNavStyle,
  HeaderHomeWrapStyle,
  HeaderMenuHoMobileStyle
} from './header.styles'
import { headerHelper } from './header.helper'
import { BurgerMenuIcon, CloseIcon } from 'assets'
import { RegisterTermsModal, NavPages } from 'components'
import { GlobalsTexts } from 'uiConstants'

export function Header (): JSX.Element {
  const {
    logo,
    handleMenuMobile,
    isAuthenticated,
    showMenu,
    colorIcon,
    handleModalTc,
    modalTC,
    handleLogout
  } = headerHelper()

  return (
    <>
      {!logo
        ? <HeaderHomeWrapStyle className='flex justify-between'>
          <div className='md:hidden w-5'></div>
          <h6>DINÁMICAS TRADE</h6>
          <div className='w-5 md:w-auto'>
            {isAuthenticated &&
              <>
                <button className='md:hidden' onClick={ handleMenuMobile }>
                  <BurgerMenuIcon primaryColor='#000' />
                </button>
                <button className='hidden md:block' onClick={() => { handleLogout(true) } }>{GlobalsTexts.logoutLabel}</button>
              </>
            }
          </div>
          <HeaderMenuHoMobileStyle className={ showMenu ? 'active' : '' }>
            <div className='p-4 flex justify-end'>
              <button onClick={handleMenuMobile}>
                <CloseIcon primaryColor="black"/>
              </button>
            </div>
            <ul>
              <li><button onClick={ () => { handleLogout(true) } }>{GlobalsTexts.logoutLabel}</button></li>
            </ul>
          </HeaderMenuHoMobileStyle>
        </HeaderHomeWrapStyle>
        : <HeaderWrapStyle className={isAuthenticated ? 'authenticated' : ''}>
        { !isAuthenticated
          ? <HeaderAnonymousWrapStyle>
            <img src={ logo } alt='logo' width='80' />
          </HeaderAnonymousWrapStyle>
          : <HeaderAuthWrapStyle>
              <div className='arrow-back'>
              </div>
              <img src={ logo } alt='logo' width='64px' />
              <HeaderWrapNavStyle>
                <NavPages />
                <button onClick={ handleMenuMobile }>
                  <BurgerMenuIcon primaryColor={ colorIcon } />
                </button>
                <HeaderMenuMobileStyle className={ showMenu ? 'active' : '' }>
                  <div className='p-4 flex justify-end'>
                    <button onClick={handleMenuMobile}>
                      <CloseIcon primaryColor={ colorIcon }/>
                    </button>
                  </div>
                  <ul>
                    <li><button onClick={ handleModalTc }>{GlobalsTexts.tcLabel}</button></li>
                    <li><button onClick={ () => { handleLogout(false) } }>{GlobalsTexts.logoutLabel}</button></li>
                  </ul>
            </HeaderMenuMobileStyle>
            </HeaderWrapNavStyle>
          </HeaderAuthWrapStyle>
        }
          <RegisterTermsModal
            showModal={ modalTC }
            handleCloseModal={handleModalTc}
          />
        </HeaderWrapStyle>
      }
    </>
  )
}
