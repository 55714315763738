import styled from 'styled-components'
import tw from 'twin.macro'

export const AgeGateModalWrap = styled.div`
  ${tw`text-center pt-16`}

  @media screen and (min-width: 768px) {
    padding: 120px 0;
  }

  .CheckboxFieldLabelStyle {
    &:after {
      background-color: ${({ theme }) => theme.color_button};
    }
  }
`

export const AgeGateModalButtonsStyle = styled.div`
  ${tw`mb-6 flex justify-center`}
  
  button {
    ${tw`
      rounded-lg
      text-2xl
      font-bold 
      py-5 px-2
      mx-2
      md:text-h5-lg
    `}
    width: 76px;

    @media screen and (min-width: 768px) {
      width: 64px;
    }
  }
`

export const AgeGateModalLogoWrapStyle = styled.div`
  ${tw`absolute top-0 left-0 flex justify-center items-center py-2 w-full rounded-t-2xl md:py-6`}
  background-color: ${({ theme }) => theme.color_footer_bg};
`
