import { useState } from 'react'
import { dynamicImp, sessionImp } from 'data/implementations'
import { dynamicUseCase, sessionUseCase } from 'domain/useCases'
import { type RewardEntity } from 'domain/entities'
import { useCustomEffect, useNavigation } from 'hooks'
import { type RewardsViewModel } from './viewModel.interfaces'
import { PrivatePath } from 'uiConstants'

export function rewardsViewModel (): RewardsViewModel {
  const { getDynamicHomeData } = dynamicUseCase(dynamicImp())
  const { getSession, clearSession } = sessionUseCase(sessionImp())
  const [rewards, setRewards] = useState<RewardEntity[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [showTcModal, setShowTcModal] = useState<boolean>(false)
  const { dynamicId } = getSession()
  // eslint-disable-next-line
  const { navigateTo, getPathParam } = useNavigation()
  const brandUrl = getPathParam('brand')
  const dynamicUrl = getPathParam('dynamic')

  function handleOnClick (reward: string): void {
    navigateTo(`/${brandUrl}/${dynamicUrl}${PrivatePath.ranking}/${reward}/1`)
  }

  useCustomEffect(() => {
    getDynamicHomeData(Number(dynamicId)).then(response => {
      setRewards(response.data.rewards)
      setLoading(false)
    }).catch((error) => {
      if (error.response.status === 401) {
        clearSession()
      }
      setLoading(false)
    })
  }, [dynamicId])

  function handleTcModal (): void {
    setShowTcModal(!showTcModal)
  }

  return {
    rewards,
    loading,
    handleOnClick,
    handleTcModal,
    showTcModal
  }
}
