import React from 'react'
import { loggedHomeViewModel } from 'viewModels'
import {
  MainContent,
  PercentageCircle,
  PointsPercentage,
  ChallengeCardWrap, HowItWorksWrap
} from './loggedHome.styles'
import { ChallengeCard, Loading } from 'components'
import { LoggedHomeText } from 'uiConstants'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { LoginSliderWrapStyle } from '../login/login.styles'
import { formatDate, useWindowDimensions } from 'hooks'
import { progressColorHelper, progressTextHelper, secondProgressTextHelper } from 'ui/helper'

export function LoggedHome (): JSX.Element {
  const { homeData, name, id, loading, dynamicName } = loggedHomeViewModel()
  const { width } = useWindowDimensions()

  const isGame = !homeData?.show_general_score
  return (
    <>
      <MainContent>
        <h5 className='text-center uppercase font-medium md:text-h5-lg'>
          {LoggedHomeText.greeting}{' '}
          {homeData?.owner_name.substring(
            0,
            homeData?.owner_name.lastIndexOf(' ')
          )}
        </h5>
        <span className='separator'></span>
        <h5 className='text-center store-name'>{name}</h5>
        <span className='separator'></span>
        <p className='text-sm md:text-base'>
          {LoggedHomeText.beesCode} <span className='font-semibold'>{id}</span>
        </p>
      </MainContent>
      <LoginSliderWrapStyle className='my-4 md:mt-5 md:mb-4'>
        {homeData?.images.length && (width && width > 768)
          ? <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true
          }}
          modules={[Pagination]}
        >
          {homeData.images.map(({ file_path_image: imagePath }, index) => {
            return (
              <SwiperSlide key={ index }>
                <img src={ imagePath } alt={`banner-${index}`} />
              </SwiperSlide>
            )
          })}
        </Swiper>
          : null
        }
        {homeData?.images_mobile.length && (width && width <= 768)
          ? <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true
          }}
          modules={[Pagination]}
        >
          {homeData.images_mobile.map(({ file_path_image: imagePath }, index) => {
            return (
              <SwiperSlide key={ index }>
                <img src={ imagePath } alt='banner' />
              </SwiperSlide>
            )
          })}
        </Swiper>
          : null
        }
      </LoginSliderWrapStyle>
      <HowItWorksWrap>
        <h5 className='text-center md:mb-4'>{LoggedHomeText.howItWorks}</h5>
        <div
          className='md:text-base'
          dangerouslySetInnerHTML={{
            __html: homeData?.description ? homeData?.description : ''
          }}
        ></div>
      </HowItWorksWrap>
      <div className='mb-6 md:text-center md:mb-8'>
        <p className='text-xs bg-gray-100 my-0 text-center p-2 rounded-2xl md:border md:border-black-20  md:text-sm md:rounded-full md:inline-block md:px-5'>
          {LoggedHomeText.dynamicStart}{' '}
          <span className='font-bold'>
          {formatDate(homeData ? homeData.execution_start_date : new Date())}
        </span>{' '}
          - {LoggedHomeText.dynamicEnd}{' '}
          <span className='font-bold'>
          {formatDate(homeData ? homeData?.execution_end_date : new Date())}
        </span>{' '}
        </p>
      </div>
      {!isGame && (
      <>
      <h5 className='text-center'>{LoggedHomeText.yourPoints}</h5>
      <div className='flex flex-col md:items-center'>
        <PointsPercentage>
          <PercentageCircle
            style={{
              background: `conic-gradient(${
                homeData?.accumulatedPointsPercentage
                  ? progressColorHelper(
                      Number(
                        homeData?.accumulatedPointsPercentage.substring(
                          0,
                          homeData?.accumulatedPointsPercentage.length - 1
                        )
                      ),
                      true
                    )
                  : ''
              } ${
                homeData?.accumulatedPointsPercentage
                  ? Number(
                      homeData?.accumulatedPointsPercentage.substring(
                        0,
                        homeData?.accumulatedPointsPercentage.length - 1
                      )
                    ) * 3.6
                  : ''
              }deg, #F3F3F3 0deg)`
            }}
          >
            <div className='progress'>
              <span className='font-bold'>
                {homeData?.accumulatedPointsPercentage}
              </span>
            </div>
          </PercentageCircle>
          <h5 className='text-center'>{progressTextHelper(Number(
            homeData?.accumulatedPointsPercentage.substring(
              0,
              homeData?.accumulatedPointsPercentage.length - 1
            )
          ))}</h5>
          <p className='text-sm text-center font-semibold md:text-base md:font-normal'>
          {secondProgressTextHelper(Number(
            homeData?.accumulatedPointsPercentage.substring(
              0,
              homeData?.accumulatedPointsPercentage.length - 1
            )
          ))}
          </p>
          <span className='text-xs bg-gray-200 my-4 text-center p-2 px-4 rounded-2xl md:mt-2 md:mb-0 md:w-full md:text-base md:px-2 md:rounded-full'>
            {LoggedHomeText.accumulated}{' '}
            <span className='font-bold'>
              {homeData?.accumulatedPoints} de {homeData?.totalPoints}
            </span>
          </span>
        </PointsPercentage>
        <p className='text-xs text-center md:text-base'>
          {LoggedHomeText.updatedData}{' '}
          {formatDate(homeData ? homeData.update_date : new Date())}, 1:00 A.M.
        </p>
      </div>
      </>
      )}
      <h5 className='text-center my-6 md:mt-16 md:mb-10'>{LoggedHomeText.yourChallenges}</h5>
      <ChallengeCardWrap>
        {homeData?.challenges.map((challenge) => (
          <ChallengeCard
            key={challenge.challenge_id}
            challengeID={''}
            description={challenge.description}
            externalLink={challenge.external_link}
            imageUrl={challenge.file_path_image}
            maxPoints={challenge.max_points}
            name={challenge.name}
            btnText={challenge.is_game ? LoggedHomeText.challengeGameCTA : LoggedHomeText.challengeCTA}
            dynamicName={dynamicName}
            percentage={challenge.percentage}
            progress={challenge.progress}
            target={challenge.target}
            progressCopy={challenge.progress_copy}
            availableOpportunities={challenge.available_opportunities}
            totalOpportunities={challenge.number_opportunities}
            isGame={!!challenge.is_game}
            score={challenge.total_score || '0'}
          />
        ))}
        {loading && <Loading />}
      </ChallengeCardWrap>
    </>
  )
}
