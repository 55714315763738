import { ActionsTypes } from './actions.types'
import { type AnyAction } from 'redux'
import { type DynamicEntity, type BrandEntity, type RankingDateEntity } from 'domain/entities'
import store from '../store'

export function dynamicAction (payload: DynamicEntity): AnyAction {
  return store.store.dispatch(
    {
      type: ActionsTypes.DYNAMIC,
      payload
    }
  )
}

export function clearDynamicAction (): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.CLEAR_DYNAMIC
  })
}

export function brandAction (payload: BrandEntity): AnyAction {
  return store.store.dispatch(
    {
      type: ActionsTypes.BRAND,
      payload
    }
  )
}

export function rankingDateAction (payload: RankingDateEntity): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.RANKING_DATE,
    payload
  })
}

export function clearBranAction (): AnyAction {
  return store.store.dispatch({
    type: ActionsTypes.CLEAR_BRAND
  })
}
