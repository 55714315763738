import { dynamicUseCase } from 'domain/useCases'
import { dynamicImp } from 'data/implementations'
import { type LoadingHelper } from './loading.interfaces'

export function loadingHelper (): LoadingHelper {
  const { getTheme } = dynamicUseCase(dynamicImp())
  const { color_button_text: iconColor } = getTheme()

  return {
    iconColor
  }
}
