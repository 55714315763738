export function progressColorHelper (progressNumber: number, circular?: boolean): string {
  if (progressNumber <= 25) {
    return !circular ? 'bg-red' : '#C31F39'
  } else if (progressNumber <= 50) {
    return !circular ? 'bg-orange' : '#EA7E00'
  } else if (progressNumber <= 99) {
    return !circular ? 'bg-yellow' : '#FFBB00'
  } else {
    return !circular ? 'bg-green' : '#34CD78'
  }
}
