import React from 'react'
import { Modal, Button } from 'components'
import { LoginErrorModalTexts } from 'uiConstants'
import { WarningDownIcon } from 'assets'
import { type LoginErrorModalProps } from './loginErrorModal.interfaces'
import { LoginErrorModalWrap } from './loginErrorModal.styles'

export function LoginErrorModal (props: LoginErrorModalProps): JSX.Element {
  const { showModal, handleCloseModal } = props

  return (
    <Modal showModal={ showModal } className='bg-transparent'>
      <LoginErrorModalWrap>
        <WarningDownIcon className='mx-auto mb-4'/>
        <h5 className='text-black mb-6'>{ LoginErrorModalTexts.title }</h5>
        <p className='text-lg mb-6'>{ LoginErrorModalTexts.description }</p>
        <p className='text-xl font-bold mb-6 md:text-lg'>{ LoginErrorModalTexts.subTitle }</p>
        <Button onAction={ handleCloseModal }>
          { LoginErrorModalTexts.buttonText }
        </Button>
      </LoginErrorModalWrap>
    </Modal>
  )
}
