import { type RankingStoreRepository, type RankingRepository } from 'domain/repositories'
import { type RankingStoreModel, type RankingListModel } from 'domain/models'
import { getRankingListService, getRankingStoreService } from 'data/services'

export function rankingImp (): RankingRepository {
  return {
    async getStoreListData (rewardID: number, pageNumber: number): Promise<RankingListModel> {
      const response = await getRankingListService(rewardID, pageNumber)
      return response.data
    }
  }
}

export function storeRankingImp (): RankingStoreRepository {
  return {
    async getRankingStore (dynamicId: number): Promise<RankingStoreModel> {
      const response = await getRankingStoreService(dynamicId)
      return response.data
    }
  }
}
