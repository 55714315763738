import { useState } from 'react'
import { storeUseCase, dynamicUseCase, sessionUseCase, generalUseCase } from 'domain/useCases'
import { storeImp, dynamicImp, sessionImp, generalImp } from 'data/implementations'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type FieldValueLogin, type LoginViewModel } from './viewModel.interfaces'
import { string, object, number } from 'yup'
import { useCustomEffect, useNavigation } from 'hooks'
import { type TypeOptions } from 'domain/entities'
import { PublicPath, PrivatePath, ErrorMessages } from 'uiConstants'
import { gtmLogin, gtmLoginHandleModal, gtmLoginSubmit, gtmLoginHandleError } from 'pages'

const Schema = object({
  identificationType: string().required(),
  userId: number().required(ErrorMessages.required).typeError(ErrorMessages.number).when('identificationType', {
    is: 'client_code',
    then: number().test('len', ErrorMessages.beesCode, val => val?.toString() ? val?.toString().length === 8 : val?.toString().length === 8)
      .required(ErrorMessages.required).typeError(ErrorMessages.beesCode)
  }),
  storeId: string()
})

export function loginViewModel (): LoginViewModel {
  const { login } = storeUseCase(storeImp())
  const { getDynamic } = dynamicUseCase(dynamicImp())
  const { updateDynamicId } = sessionUseCase(sessionImp())
  const { getIdentifications } = generalUseCase(generalImp())
  // eslint-disable-next-line
  const { short_description: dynamicDescription, images, name: dynamicName, id: dynamicId, images_mobile: imagesMobile } = getDynamic()
  const [gtmDataSubmit, setGtmDataSubmit] = useState<{ message: string, documentType: string }>({ message: '', documentType: '' })
  const [identifications, setIdentifications] = useState<TypeOptions[]>()
  const [storeList, setStoreList] = useState<unknown | TypeOptions[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [modalError, setModalError] = useState<boolean>(false)
  const { navigateTo, getPathParam } = useNavigation()
  const brandUrl = getPathParam('brand')
  const dynamicUrl = getPathParam('dynamic')

  const { control, handleSubmit, formState: { isValid, errors }, watch, resetField, clearErrors } = useForm<FieldValueLogin>({
    mode: 'onChange',
    // @ts-expect-error
    resolver: yupResolver(Schema),
    defaultValues: {}
  })

  const identificationType = watch('identificationType')
  const userId = watch('userId')

  const onSubmit: SubmitHandler<FieldValueLogin> = (data) => {
    gtmLogin(dynamicName, data.identificationType)
    setLoading(true)
    login({ identification_type: data.storeId ? 'client_code' : data.identificationType, id: data.storeId ? data.storeId : data.userId, dynamic_id: String(dynamicId) }).then(response => {
      if (response.data.needsSelectStore) {
        setStoreList(response.data.store as (unknown | TypeOptions[]))
      } else {
        if (response.data.termsAcceptance.accepted) {
          sessionStorage.removeItem('dynamicId')
          updateDynamicId(dynamicId)
          navigateTo(`/${brandUrl}/${dynamicUrl}${PrivatePath.loggedHome}`)
          gtmLoginSubmit(dynamicName, data.userId, data.identificationType)
        } else {
          navigateTo(`/${brandUrl}/${dynamicUrl}${PublicPath.register}`)
          gtmLoginSubmit(dynamicName, data.userId, data.identificationType)
        }
      }
      setLoading(false)
    }).catch((error) => {
      const identificationName = identifications?.filter((identification) => identification.id === data.identificationType)[0]
      setGtmDataSubmit({
        message: error.response.data.message,
        documentType: identificationName ? String(identificationName.name) : ''
      })
      gtmLoginHandleError(dynamicName, identificationName ? String(identificationName.name) : '', error.response.data.message)
      setLoading(false)
      setModalError(true)
    })
  }

  function handleCloseModalError (): void {
    gtmLoginHandleModal(dynamicName, gtmDataSubmit.documentType, gtmDataSubmit.message)
    setModalError(false)
  }

  useCustomEffect(() => {
    if (storeList) {
      setStoreList('')
      resetField('storeId')
    }
  }, [identificationType, userId])

  useCustomEffect(() => {
    clearErrors('userId')
  }, [identificationType])

  useCustomEffect(() => {
    getIdentifications().then(response => {
      setIdentifications(response.data.item)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  return {
    control,
    handleSubmit,
    onSubmit,
    dynamicDescription,
    identifications,
    isValid,
    loading,
    modalError,
    handleCloseModalError,
    storeList,
    images,
    dynamicName,
    errors,
    imagesMobile
  }
}
