import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'

export function gtmNavPages (dynamicName: string, componentName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Content',
      action: 'Content',
      label: 'Menu',
      interaction: 'true',
      additionalData: {
        campaign_description: dynamicName,
        component_name: componentName
      }
    })
  )
}
