import { type GeneralRepository } from '../../domain/repositories/general.repository'
import { type IdentificationModel } from '../../domain/models/general.model'
import { getIdentificatinService } from 'data/services/general.service'

export function generalImp (): GeneralRepository {
  return {
    async getIdentifications (): Promise<IdentificationModel> {
      const response = await getIdentificatinService()
      return response.data
    }
  }
}
