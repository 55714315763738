import axios, { type AxiosResponse, type AxiosError } from 'axios'
import { type RankingListModel, type RankingStoreModel } from 'domain/models'
import { ServicesPaths } from 'data/constants'

export async function getRankingListService (rewardID: number, pageNumber: number): Promise<AxiosResponse<RankingListModel, AxiosError>> {
  return await axios.get(`${ServicesPaths.ranking}/${rewardID}/list/?page=${pageNumber}`)
}

export async function getRankingStoreService (dynamicId: number): Promise<AxiosResponse<RankingStoreModel, AxiosError>> {
  return await axios.get(`${ServicesPaths.rankingDynamic}/${dynamicId}`)
}
