import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'

export function gtmAgeGateYes (dynamicName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Age Gate',
      action: 'Interaction',
      label: 'Yes',
      interaction: 'False',
      additionalData: {
        campaign_description: dynamicName
      }
    })
  )
}

export function gtmAgeGateNo (dynamicName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Age Gate',
      action: 'Interaction',
      label: 'No',
      interaction: 'False',
      additionalData: {
        campaign_description: dynamicName
      }
    })
  )
}

export function gtmAgeGateRemember (dynamicName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Age Gate',
      action: 'Interaction',
      label: 'Remember me',
      interaction: 'False',
      additionalData: {
        campaign_description: dynamicName,
        component_name: 'No volver a preguntar'
      }
    })
  )
}
