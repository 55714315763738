import styled from 'styled-components'
import tw from 'twin.macro'
import { type ButtonStyles } from './button.interfaces'

export const ButtonStyle = styled.button<ButtonStyles>`
  ${tw`
    border-none
    rounded-2xl
  `}
  ${({ size }) =>
    size === 'small'
    ? tw`text-xs py-3 font-medium px-6`
    : size === 'normal'
    ? tw`text-base py-3 font-semibold px-6`
    : size === 'large' && tw`text-lg py-4 font-semibold px-6`
  }
  background-color: ${({ theme }) => theme.color_button};
  color: ${({ theme }) => theme.color_button_text};

  &:disabled {
    ${tw`
      bg-black-20
      cursor-default
      text-black-40
    `}
  }
`
