import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'
import { LoggedHomeText } from 'uiConstants'

export function gtmCompleteChallenge (challengeName: string, dynamicName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Content',
      action: 'Card',
      label: 'Tus desafíos',
      additionalData: {
        component_name: challengeName,
        element_text: LoggedHomeText.challengeCTA,
        campaign_description: dynamicName
      }
    })
  )
}
