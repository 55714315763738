import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function HomeIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#000000', className } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      fill='none'
      viewBox='0 0 24 25'
      className={ className }
    >
      <path
        fill={ primaryColor }
        fillRule='evenodd'
        d='M21.66 12.689v9.626a2.19 2.19 0 01-2.183 2.185H16.07a1.13 1.13 0 01-1.129-1.132v-5.676A2.95 2.95 0 0012 14.753a2.95 2.95 0 00-2.941 2.939v5.676a1.13 1.13 0 01-1.13 1.132H4.524a2.19 2.19 0 01-2.182-2.185V12.69h-.018c-.66 0-1.223-.363-1.492-.965-.272-.6-.218-1.31.266-1.755l9.678-8.915C11.132.724 11.516.5 12 .5c.484 0 .902.19 1.226.554l9.677 8.915c.484.444.539 1.155.267 1.755-.27.602-.833.965-1.492.965h-.019z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}
