import styled from 'styled-components'
import tw from 'twin.macro'

export const RankedPositionWrapper = styled.div`
  ${tw`flex flex-col items-center justify-center rounded-2xl p-4 w-56 mx-auto my-6`}
  box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08);
  
  @media screen and (min-width: 768px) {
    ${tw`w-full`}
    max-width: 418px;
  }
`

export const RankingWrap = styled.div`
  @media screen and (min-width: 768px) {
    ${tw`mx-auto`}
    max-width: 1310px;
  }
`
