import { useState, useRef } from 'react'
import { sessionUseCase, storeUseCase, dynamicUseCase } from 'domain/useCases'
import { sessionImp, storeImp, dynamicImp } from 'data/implementations'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type FieldValueRegister, type RegisterViewModel, type ErrorModalType } from './viewModel.interfaces'
import { string, object, boolean, number } from 'yup'
import { useNavigation, useCustomEffect } from 'hooks'
import { PublicPath, PrivatePath, ErrorMessages } from 'uiConstants'
import { gtmRegister, gtmRegisterCloseTCModal, gtmRegisterSuccess, gtmErrorRegisterModal } from 'pages'

const Schema = object({
  customer: string(),
  beesCode: string(),
  name: string().required(ErrorMessages.required).matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/g, ErrorMessages.onlyAlphabets),
  lastName: string().required(ErrorMessages.required).matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/g, ErrorMessages.onlyAlphabets),
  email: string().required(ErrorMessages.required).email(ErrorMessages.email),
  phone: number().required(ErrorMessages.required).typeError(ErrorMessages.number),
  tc: boolean().oneOf([true]).required(),
  tcc: boolean()
})

export function registerViewModel (): RegisterViewModel {
  const { getSession, updateDynamicId } = sessionUseCase(sessionImp())
  const { termsAcceptance } = getSession()
  // eslint-disable-next-line
  const { getStore, registerStore } = storeUseCase(storeImp())
  const { name, id } = getStore()
  const { getDynamic, getBrand } = dynamicUseCase(dynamicImp())
  const { legal_term: legalTerm, name: dynamicName, id: dynamicId } = getDynamic()
  const { name: brandName } = getBrand()
  const [loading, setLoading] = useState<boolean>(false)
  const [modalTC, setModalTC] = useState<boolean>(false)
  const [showModalError, setShowModalError] = useState<ErrorModalType>('')
  const { navigateTo, getPathParam, replaceAndNavigateTo } = useNavigation()
  const brandUrl = getPathParam('brand')
  const dynamicUrl = getPathParam('dynamic')
  const tcButtonEl = useRef(document.getElementById('tc-button'))

  const { control, handleSubmit, formState: { isValid, errors } } = useForm<FieldValueRegister>({
    mode: 'onChange',
    // @ts-expect-error
    resolver: yupResolver(Schema),
    defaultValues: {
      customer: name,
      beesCode: id,
      name: termsAcceptance?.previousData ? termsAcceptance?.previousData.name : '',
      lastName: termsAcceptance?.previousData ? termsAcceptance?.previousData.lastname : '',
      email: termsAcceptance?.previousData ? termsAcceptance?.previousData.email : '',
      phone: termsAcceptance?.previousData ? termsAcceptance?.previousData.phone : '',
      tcc: false
    }
  })

  const onSubmit: SubmitHandler<FieldValueRegister> = (data) => {
    gtmRegister(dynamicName, data.tcc ? 'Acepto' : 'No acepto')
    setLoading(true)
    registerStore({
      store_id: Number(id),
      legal_term_id: legalTerm.id,
      dynamic_id: Number(dynamicId),
      get_information: data.tcc,
      registration_data: {
        name: data.name,
        lastname: data.lastName,
        email: data.email,
        phone: data.phone
      }
    }).then(() => {
      updateDynamicId(Number(dynamicId))
      setLoading(false)
      gtmRegisterSuccess(dynamicName, id)
      navigateTo(`/${brandUrl}/${dynamicUrl}${PrivatePath.loggedHome}`)
    }).catch((e) => {
      if (e.response.data.message) {
        if (e.response.data.message.includes('Usuario ya se encuentra registrado.')) {
          setShowModalError('alreadyRegistered')
          gtmErrorRegisterModal(dynamicName, 'Usuario ya registrado')
        } else {
          setShowModalError('errorDynamic')
          gtmErrorRegisterModal(dynamicName, 'Usuario no inscrito en la dinámica')
        }
      } else {
        setShowModalError('errorDynamic')
        gtmErrorRegisterModal(dynamicName, 'Usuario no inscrito en la dinámica')
      }
      setLoading(false)
    })
  }

  useCustomEffect(() => {
    if (id && dynamicId) {
      const el = document.getElementById('tc-button') as HTMLElement
      if (el) {
        tcButtonEl.current = el
        tcButtonEl.current.addEventListener('click', () => {
          setModalTC(true)
        })
      }
    } else {
      replaceAndNavigateTo(`/${brandUrl}/${dynamicUrl}${PublicPath.login}`)
    }
  }, [])

  function handleCloseModalError (): void {
    if (showModalError === 'alreadyRegistered') {
      replaceAndNavigateTo(`/${brandUrl}/${dynamicUrl}${PublicPath.login}`)
    } else {
      replaceAndNavigateTo('/')
    }
  }

  function handleCloseModal (): void {
    gtmRegisterCloseTCModal(dynamicName)
    setModalTC(false)
  }

  return {
    control,
    handleSubmit,
    onSubmit,
    isValid,
    loading,
    modalTC,
    handleCloseModal,
    brandName,
    dynamicName,
    errors,
    showModalError,
    handleCloseModalError
  }
}
