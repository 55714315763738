export enum LoginErrorModalTexts {
  title = '¡LO SENTIMOS!',
  description = 'Actualmente no haces parte de la dinámica.',
  subTitle = ' ¡Gracias por tu interés!',
  buttonText = 'ACEPTAR'
}

export enum DynamicErrorModalText {
  title = '¡LO SENTIMOS!',
  description = 'La Dinámica [dynamicName] no esta disponible!',
  subTitle = 'Dinamica disponible desde <strong>[starDateText]</strong> hasta <strong>[endDate]</strong>',
  titleNotFound = '¡LO SENTIMOS!',
  descriptionNotFound = 'Esta dinámica no existe, consulta con tu asesor.',
}
