export enum ServicesPaths {
  login = '/v1/auth/login/store',
  logout = '/v1/auth/logout/store',
  register = '/v1/stores/register',
  identificationTypes = '/v1/identification-types',
  dynamic = '/v1/dynamics/by-slugs',
  dynamicHome = '/v1/store/dynamics',
  ranking = '/v1/store/ranking/awards',
  rankingDynamic = '/v1/store/ranking/dynamics',
  allDynamics = '/v1/dynamics/',
}
