import { useState } from 'react'
import { useCustomEffect, useNavigation } from 'hooks'
import { type HomeViewModel } from './viewModel.interfaces'
import { dynamicUseCase, sessionUseCase } from 'domain/useCases'
import { dynamicImp, sessionImp } from 'data/implementations'
import { type DynamicEntity } from 'domain/entities'
import { PublicPath, PrivatePath } from 'uiConstants'

export function homeViewModel (): HomeViewModel {
  const { getDynamicsList, dynamicData, clearBrand, clearDynamic } = dynamicUseCase(dynamicImp())
  const { updateDynamicId, getSession } = sessionUseCase(sessionImp())
  const { token } = getSession()
  const [dynamicsList, setDinamicsList] = useState<DynamicEntity[]>([])
  const [userInfo, setUserInfo] = useState({
    name: '',
    id: '',
    client_type_id: '',
    name_owner: ''
  })
  const [loading, setLoading] = useState<boolean>(true)
  const { navigateTo } = useNavigation()

  useCustomEffect(() => {
    setLoading(true)
    clearBrand()
    clearDynamic()
    getDynamicsList().then(response => {
      if (response.data.store) {
        setUserInfo(response.data.store)
      } else {
        setUserInfo({
          name: '',
          id: '',
          client_type_id: '',
          name_owner: ''
        })
      }
      setLoading(false)
      setDinamicsList(response.data.item)
    }).catch(() => {
      setLoading(false)
    })
  }, [token])

  function handleButton (dynamic: string, brand: string, storesAccept: boolean): void {
    setLoading(true)
    dynamicData({
      brand_slug: brand,
      dynamic_slug: dynamic
    }).then(reponse => {
      if (storesAccept) {
        if (token) {
          if (sessionStorage.getItem('dynamicId')) {
            sessionStorage.setItem('dynamicId', String(reponse.data.id))
          } else {
            updateDynamicId(reponse.data.id)
          }
          navigateTo(`/${brand}/${dynamic}${PrivatePath.loggedHome}`)
        }
      } else {
        navigateTo(`/${brand}/${dynamic}${PublicPath.login}`)
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  return {
    dynamicsList,
    handleButton,
    loading,
    userInfo
  }
}
