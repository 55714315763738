import styled from 'styled-components'
import tw from 'twin.macro'

export const RewardsWrapStyle = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 1240px;
    ${tw`flex flex-wrap mx-auto`};
  }
`

export const RewardsCardWrapStyle = styled.div`
  ${tw`
    rounded-2xl
    mb-6
    p-4
    bg-white
    relative
  `}
  border: 1px solid #F3F3F3;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.08);

  > img {
    @media screen and (min-width: 768px) {
      max-height: calc((((100vw - 4%) - 8rem) * 205) / 1240);
      min-height: calc((((100vw - 4%) - 8rem) * 205) / 1240);
    }
    @media screen and (min-width: 1240px) {
      max-height: 205px;
      min-height: 205px;
    }
  }

  @media screen and (min-width: 768px) {
    ${tw`justify-start`}
    max-width: 32%;
    flex: 1 32%;
    margin-right: 2%;
    padding-bottom: 86px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    h6 {
      ${tw`flex justify-center items-center`}
      min-height: 63px;
    }
  }
`

export const RewardsCardTagStyle = styled.div`
  > div {
    ${tw`
    bg-black-10
    flex justify-center items-center
    rounded-3xl border border-solid border-black-20
    py-3 px-4
    `}
  }

  @media screen and (min-width: 768px) {
    ${tw`flex absolute left-0 w-full justify-center`}
    bottom: 24px;
  }
`

export const RewardsTCButtonStyle = styled.button`
  ${tw`underline`}
  color: ${({ theme }) => theme.color_button};
`
