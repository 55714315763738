import styled from 'styled-components'
import tw from 'twin.macro'

export const HeaderWrapStyle = styled.header`
  ${tw`mb-2 md:mb-6`}
  
  .NavWrapStyle {
    ${tw`hidden md:block`}
  }

  &.authenticated {
    @media screen and (min-width: 768px) {
      ${tw`fixed top-0 left-0 z-50 w-full`}
    }
  }
`

export const HeaderAnonymousWrapStyle = styled.header`
  ${tw`flex justify-center p-4`}
  background-color: ${({ theme }) => theme.color_footer_bg};

  @media screen and (min-width: 768px) {
    ${tw`justify-start py-4 px-10`}
  }
`

export const HeaderAuthWrapStyle = styled.div`
  background-color: ${({ theme }) => theme.color_footer_bg};
  ${tw`
    p-4 md:px-11
    flex justify-between items-center
  `}
  
  .arrow-back {
    ${tw`block md:hidden`}
    width: 18px;
  }
`

export const HeaderWrapNavStyle = styled.div`
  ${tw`flex`}
`

export const HeaderMenuMobileStyle = styled.div`
  ${tw`
    fixed top-0
    w-full h-full
  `}
  z-index: 20;
  transition: 0.5s;
  right: -100%;
  &.active {
    right: 0;
  }
  background-color: ${({ theme }) => theme.color_footer_bg};
  
  * {
    color: ${({ theme }) => theme.color_footer_text};
  }
  
  
  ul {
    list-style: none;
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 0;
    ${tw`
      px-4
    `}
  }
  
  li {
    border-color: ${({ theme }) => theme.color_footer_text};
    ${tw`border-b`}
    padding: 10px;
    a {
      
    }
    
    &:first-child {
      ${tw`border-t`}
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 375px;
  }
`

export const HeaderMenuHoMobileStyle = styled.div`
  ${tw`
    fixed top-0
    w-full h-full
  `}
  z-index: 20;
  transition: 0.5s;
  right: -100%;
  &.active {
    right: 0;
  }
  background-color: #FFFF00;

  
  * {
    color: black;
  }
  
  
  ul {
    list-style: none;
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 0;
    ${tw`
      px-4
    `}
  }
  
  li {
    border-color: black;
    ${tw`border-b`}
    padding: 10px;
    a {
      
    }
    
    &:first-child {
      ${tw`border-t`}
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const HeaderHomeWrapStyle = styled.header`
  ${tw`p-4 text-center mb-6 md:px-10`};
  background-color: #FFFF00;

  > div {
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
`
