import styled from 'styled-components'
import tw from 'twin.macro'

export const MainContent = styled.div`
  ${tw`flex flex-col items-center gap-2`}

  .separator {
    ${tw`hidden`}
  }

  @media screen and (min-width: 768px) {
    ${tw`mx-auto flex-row justify-between items-center`}
    max-width: 812px;
    
    .separator {
      ${tw`rounded-full block`}
      background-color: ${({ theme }) => theme.color_title};
      height: 4px;
      width: 4px;
    }

    > * {
      ${tw`text-base font-normal`}
    }
    > .store-name {
      ${tw`font-bold`}
    }
  }


`

export const GrayTag = styled.span`
 ${tw`bg-black-10 border border-black-20 font-medium text-black-70 text-center rounded-2xl px-2 py-1 text-xs md:text-base`}
`
export const PointsPercentage = styled.div`
 ${tw`rounded-xl flex flex-col items-center gap-2 bg-white mt-14 mb-6`};
 box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08);

  @media screen and (min-width: 768px) {
    ${tw`relative mt-5 gap-0 mb-5`};
    padding: 8px 8px 8px 71px;
    max-width: 308px;
  }
`

export const PercentageCircle = styled.div`
 ${tw`rounded-full relative w-[80px] h-[80px] -mt-10 flex justify-center items-center`};

 @media screen and (min-width: 768px) {
   ${tw`absolute`};
   left: -60px;
   height: 120px;
   width: 120px;
   top: 50%;
   margin-top: -60px;
   box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 16px -4px, rgba(0, 0, 0, 0.08) 0px 4px 8px;
   
   .progress {
     span {
       font-size: 2rem;
     }
   }
 }
  
 &::before{
    content: '';
    position: absolute;
    height: 84%;
    width: 84%;
    background-color: white;
    border-radius: 50%;
 }
  
 span{
    position: relative;
 }
`

export const HowItWorksWrap = styled.div`
  ${tw`mb-4`};
  @media screen and (min-width: 768px) {
    max-width: 812px;
    ${tw`mx-auto mb-5`};
  }
`

export const ChallengeCardWrap = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 1240px;
    ${tw`flex flex-wrap mx-auto`};
  }
`
