import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'

export function gtmLogin (dynamicName: string, documentType: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Login',
      action: 'Submit',
      label: 'LoginAPI',
      additionalData: {
        campaign_description: dynamicName,
        component_name: documentType
      }
    })
  )
}

export function gtmLoginSubmit (dynamicName: string, userId: string, documentType: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Login',
      action: 'Success',
      label: 'LoginAPI',
      additionalData: {
        campaign_description: dynamicName,
        component_name: documentType,
        userUid: userId
      }
    })
  )
}

export function gtmLoginHandleError (dynamicName: string, documentType: string, message: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Login',
      action: 'Error',
      label: 'LoginAPI',
      additionalData: {
        campaign_description: dynamicName,
        component_name: documentType,
        element_text: message
      }
    })
  )
}

export function gtmLoginHandleModal (dynamicName: string, documentType: string, message: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Login',
      action: 'Error',
      label: 'LoginAPI',
      additionalData: {
        campaign_description: dynamicName,
        component_name: documentType,
        element_text: message
      }
    })
  )
}
