export enum LoginTexts {
  ageGateTitle = '¿ERES MAYOR DE EDAD?',
  ageGateDescription = '*No selecciones esta opción si compartes este dispositivo con menores de edad.',
  ageGateCheckbox = 'No volver a preguntar',
  howDynamicWork = '¿CÓMO FUNCIONA LA DINÁMICA?',
  loginTitle = 'INGRESA A LA DINÁMICA',
  loginButton = 'INGRESAR',
  selectStoreLabel = 'Selecciona la tienda'
}

export enum RegisterTexts {
  intro = 'Ingresa tus datos para contactarte en caso de ser ganador de la dinámica.',
  customerLabel = 'Cliente',
  BeesCodeLabel = 'Código Bees',
  nameLabel = 'Nombre',
  lastNameLabel = 'Apellido',
  emailLabel = 'Correo electrónico',
  phoneLabel = 'Telefono',
  tcLabel = '<p>Confirmo que soy mayor de edad, y que he leído y acepto los <span id="tc-button" class="underline">Términos y Condiciones</span> del sitio web. Además, autorizo finalidades señaladas en el siguiente <a class="underline" target="_blank" href="https://api.dinamicastrade.com/storage/Aviso-Privacidad-para-Consentimiento-Informado-BEES-Col_nov-2023.pdf">Aviso de Privacidad para Consentimiento Informado</a></p>',
  alreadyRegisteredTitle = '¡Ups!',
  alreadyRegisteredDes = 'Ya te encuentras registrado en esta dinámica.',
  alreadyRegisteredSub = '¡Te invitamos a ingresar a la dinámica!',
  errorDynamicTitle = '¡LO SENTIMOS!',
  errorDynamicDes = 'Actualmente no haces parte de la dinámica.',
  errorDynamicSub = '¡Gracias por tu interés!',
}

export enum LoggedHomeText {
  greeting = 'Hola',
  beesCode = 'Código BEES:',
  howItWorks = '¿CÓMO FUNCIONA LA DINÁMICA?',
  dynamicStart = 'Inicio: ',
  dynamicEnd = 'Fin: ',
  yourPoints = 'TU PUNTAJE',
  yourPointsTitle = '¡Nunca es tarde!',
  yourPointsDescription = 'Cumple tus desafíos',
  accumulated = 'Tu acumulado: ',
  updatedData = 'Datos actualizados al',
  yourChallenges = 'TUS DESAFÍOS',
  challengeCTA = 'COMPLETAR EL DESAFÍO',
  challengeGameCTA = 'PARTICIPAR'
}

export enum RewardsText {
  title = 'PREMIOS',
  rankingPositionLabel = 'Posición en el ranking:'
}

export enum RankingTexts {
  title = 'RANKING',
  position = 'Tu posición',
  winner = 'Has ganado',
  messagesErrorService = 'No tienes una posición en el ranking',
  general = '¡Lo sentimos!, no quedaste en el ranking de ganadores.',
  winnersTitle = 'GANADORES',
  tableCol1 = 'POSICIÓN',
  tableCol2 = 'CLIENTE',
  showing = 'Mostrando'
}

export enum HomeTexts {
  title = 'DINÁMICAS',
  availableDynamics = 'Estas son las dinámicas activas actualmente',
  availableDynamicsAuth = 'Estas son las dinámicas activas actualmente',
  ctaDynamic = 'Ir a la dinámica',
  titleNotAvailable = '¡LO SENTIMOS!',
  descriptionNotAvailable = 'Actualmente no tenemos dinámicas activas.',
  descriptionNotAvailableAuth = 'Actualmente no estás participando en ninguna Dinámica.'
}
