import { type StoreEntity } from 'domain/entities'

export const storeState: StoreEntity = {
  id: '',
  client_type_id: '',
  name: '',
  owner_id_type: '',
  owner_id_number: '',
  address: '',
  city: '',
  regional: '',
  management: '',
  business_unit: '',
  sub_channel: ''
}
