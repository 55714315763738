type AdditionalType = Record<string, string>
type datalayerData = Record<string, string | undefined>

interface DatalayerHelper {
  gtmId?: string
  dataLayer: datalayerData
}

interface DatalayerHelperProps {
  type?: string
  category?: string
  action?: string
  label?: string
  interaction?: string
  additionalData?: AdditionalType
}

export function datalayerHelper (props: DatalayerHelperProps): DatalayerHelper {
  const { type, category, action, label, additionalData, interaction } = props

  return {
    gtmId: process.env.GTM_ID,
    dataLayer: {
      event: type,
      event_category: category,
      event_action: action,
      event_label: label,
      interaction,
      ...additionalData
    }
  }
}
