import axios, { type AxiosResponse, type AxiosError } from 'axios'
import { ServicesPaths } from 'data/constants'
import { type LoginParams, type RegisterParams } from 'domain/repositories'
import { type LoginModel, type RequestSuccessModel } from 'domain/models'

export async function loginService (params: LoginParams): Promise<AxiosResponse<LoginModel, AxiosError>> {
  return await axios.post(ServicesPaths.login, {
    ...params
  })
}

export async function registerService (params: RegisterParams): Promise<AxiosResponse<LoginModel, AxiosError>> {
  return await axios.post(ServicesPaths.register, {
    ...params
  })
}

export async function logoutService (): Promise<AxiosResponse<RequestSuccessModel, AxiosError>> {
  return await axios.post(ServicesPaths.logout)
}
