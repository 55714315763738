import React from 'react'
import { CheckboxFieldStyle, CheckboxFieldWrapStyle, CheckboxFieldLabelStyle } from './checkboxField.styles'
import { type CheckboxFieldProps } from './checkboxField.interfaces'
import { useController } from 'react-hook-form'

export function CheckboxField (props: CheckboxFieldProps): JSX.Element {
  const { id, label = '', control, name, isRequired = false } = props
  const { field: { onChange, onBlur, value, ref } } = useController({
    name,
    control,
    rules: { required: isRequired },
    defaultValue: ''
  })

  return (
    <CheckboxFieldWrapStyle>
      <CheckboxFieldStyle
        type='checkbox'
        checked={value}
        required={ isRequired }
        onChange={ onChange }
        id={ id }
        ref={ ref }
        onBlur={ onBlur }
        value={ value }
      />
      <CheckboxFieldLabelStyle htmlFor={ id } dangerouslySetInnerHTML={ { __html: label } }>
      </CheckboxFieldLabelStyle>
    </CheckboxFieldWrapStyle>
  )
}
