import React from 'react'
import { ThemeProvider } from 'styled-components'
import { layoutViewModel } from 'viewModels'
import GlobalStyle from '../../globalStyles'
import { Footer } from './footer'
import { Header } from './header'
import { SpinnerWrapStyle } from './layout.styles'
import { Helmet } from 'react-helmet'
import { DynamicErrorModal } from 'components'

interface LayoutProps {
  children: JSX.Element
}
export function Layout (props: LayoutProps): JSX.Element {
  const { children } = props
  const { theme, loading, brandName, dynamicName, dynamicError, dynamicErrorType } = layoutViewModel()

  return (
    <ThemeProvider theme={ theme }>
      {theme.file_path_favicon && <Helmet>
        <title>{ brandName } | { dynamicName }</title>
        <link rel='icon' href={ theme.file_path_favicon } sizes='16x16' />
      </Helmet>
      }
      <GlobalStyle />
      {!dynamicError
        ? <>
          <Header />
            <main className='px-4'>
              { children }
            </main>
          <Footer />
        </>
        : <DynamicErrorModal
          showModal={ dynamicError }
          errorType={ dynamicErrorType }
        />
      }
      {loading &&
        <SpinnerWrapStyle>
            Cargando la Dinámica.
        </SpinnerWrapStyle>
      }
    </ThemeProvider>
  )
}
