import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function RankingIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#000000', className } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='17'
      fill='none'
      viewBox='0 0 16 17'
      className={ className}
    >
      <path
        fill={ primaryColor }
        fillRule='evenodd'
        d='M14.73 3.996a.177.177 0 01.051-.157l1.19-1.16a.1.1 0 00.024-.1.094.094 0 00-.078-.066l-1.645-.24a.173.173 0 01-.133-.096L13.403.685a.095.095 0 00-.088-.054.094.094 0 00-.088.054l-.736 1.492a.177.177 0 01-.133.096l-1.644.24a.09.09 0 00-.079.065.096.096 0 00.024.101l1.19 1.16c.043.04.062.1.052.157l-.281 1.638a.096.096 0 00.038.096.093.093 0 00.102.008l1.47-.772a.173.173 0 01.084-.021c.029 0 .057.006.083.02l1.472.773a.093.093 0 00.103-.008.096.096 0 00.038-.096l-.28-1.638zm-3.941 5.826a2 2 0 012-2h1.042a2 2 0 012 2v4.547a2 2 0 01-2 2h-1.042a2 2 0 01-2-2V9.822zm-3.394.832a2 2 0 00-2 2v1.715a2 2 0 002 2h1.04a2 2 0 002-2v-1.715a2 2 0 00-2-2h-1.04zM1.19 13.99a1.189 1.189 0 100 2.378h2.664a1.189 1.189 0 000-2.378H1.189z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}
