import React from 'react'
import { Modal, Button } from 'components'
import { type RegisterTermsModalProps } from './registerTermsModal.interfaces'
import { registerTermsModalHelper } from './registerTermsModal.helper'
import { GlobalsTexts } from 'uiConstants'

export function RegisterTermsModal (props: RegisterTermsModalProps): JSX.Element {
  const { showModal, handleCloseModal } = props
  const { legalTerm } = registerTermsModalHelper()

  return (
    <Modal showModal={ showModal } className='tc-modal bg-transparent'>
      <div className='text-center px-4 max-w-full'>
        <h5 className='mb-6'> TÉRMINOS Y CONDICIONES </h5>
        <div
          className='mb-6 text-left h-screen-50 overflow-y-scroll'
          dangerouslySetInnerHTML={ { __html: legalTerm } }>
        </div>
        <Button onAction={ handleCloseModal }>
          { GlobalsTexts.closeText }
        </Button>
      </div>
    </Modal>
  )
}
