import styled from 'styled-components'
import tw from 'twin.macro'

export const LoginSliderWrapStyle = styled.div`
  ${tw`-mx-4 md:mx-auto`}

  @media screen and (min-width: 768px) {
    max-width: 812px;
  }
  img {
    ${tw`w-full`}

    @media screen and (min-width: 768px) {
      border-radius: 40px;
    }
  }
`

export const LoginTitleWrap = styled.div`
  ${tw`text-center mb-2`}
  
  span {
    color: ${({ theme }) => theme.color_title};
    ${tw`block w-full text-base font-normal`};
    @media screen and (min-width: 768px) {
      ${tw`text-h5-lg font-extrabold inline w-auto`};
    }
  }

  @media screen and (min-width: 768px) {
    ${tw`mx-auto mb-5`}
    max-width: 812px;
  }
`

export const LoginDescriptionWrap = styled.div`
  ${tw`mb-6`};
  
  @media screen and (min-width: 768px) {
    ${tw`mb-0`};
    flex: 1;
    max-width: 386px;
  }
`

export const LoginFormWrap = styled.form`
  @media screen and (min-width: 768px) {
    ${tw`rounded-2xl mx-0 bg-white`}
    box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.08);
    padding: 40px;
    flex: 1;
    max-width: 386px;
  }
`

export const FormAndTitleWrap = styled.div`
  @media screen and (min-width: 768px) {
    ${tw`flex justify-between mx-auto`}
    max-width: 812px;
  }
`
