/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components'
import tw from 'twin.macro'

export const TabsWrapper = styled.div`
  ${tw`flex border-b w-screen`}
  margin-left: -1rem;
  margin-right: -1rem;
  padding-right: 3px;
  padding-left: 3px;
  border-color: ${({ theme }) => theme.color_button};
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }

  @media screen and (min-width: 768px) {
    ${tw`w-full m-0 p-0`}
  }
`

export const Tab = styled.button`
  ${tw`min-w-[120px] w-[120px] p-2 text-xs font-bold`}
  background-color: white;
  border: ${({ theme }) => `solid 1px ${theme.color_button}`};
  border-bottom: none;
  color: ${({ theme }) => theme.color_button};
  border-radius: 4px 4px 0px 0px;
  margin: 0 1px;
  
  &.isActive {
    background-color: ${({ theme }) => theme.color_button};
    color: ${({ theme }) => theme.color_button_text};
  }
  
  &.inactive {
    ${tw`bg-black-20 text-black-30 border-black-10`}
  }

  @media screen and (min-width: 768px) {
    ${tw`text-base ml-0 font-normal`}
    max-width: 326px;
    padding: 18px 8px;
    min-width: 326px;
  }
`
export const ContentTab = styled.div`
  ${tw`[&>*:nth-child(even)]:bg-black-10 [&>*:nth-child(odd)]:bg-white`}
`

export const TabPagination = styled.div`
  ${tw`flex justify-center gap-2 font-bold`}
  color: ${({ theme }) => theme.color_button};
  
  li {
    ${tw`bg-black-10 w-10 h-10 flex items-center justify-center rounded-lg`}
    
    &.rc-pagination-item-active {
      background-color: ${({ theme }) => theme.color_button};
      color: ${({ theme }) => theme.color_button_text};
    }
 }

  .rc-pagination-options {
    display: none;  
  }
`
