import React from 'react'
import { ButtonStyle } from './button.styles'
import { type ButtonProps } from './button.interfaces'

export function Button (props: ButtonProps): JSX.Element {
  const { size = 'normal', children, onAction, type, disabled = false } = props
  return (
    <ButtonStyle size={ size } onClick={onAction} type={type} disabled={disabled}>
      { children }
    </ButtonStyle>
  )
}
