import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function ArrowDownIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#393939' } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path
        stroke={ primaryColor }
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M10.667 1.5L6 6.167 1.333 1.5'
      ></path>
    </svg>
  )
}
