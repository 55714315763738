import styled from 'styled-components'
import tw from 'twin.macro'

export const FooterWrapStyle = styled.footer`
  background-color: ${({ theme }) => theme.color_footer_bg};
  ${tw`
    py-6 px-4
    mt-8
  `}
`

export const FooterUlStyle = styled.ul`
  list-style: none;
  margin-block: 0;
  margin-inline: 0;
  padding-inline-start: 0;
  ${tw`text-center md:flex md:justify-center`}

  * {
    color: ${({ theme }) => theme.color_footer_text};
  }
  
  li {
    ${tw`text-sm mb-2 relative md:text-base`};

    @media screen and (min-width: 768px) {
      padding-left: 24px;
      margin-left: 24px;
      &:before {
        content: '';
        border-radius: 100%;
        width: 4px;
        height: 4px;
        background-color: ${({ theme }) => theme.color_footer_text};
        display: block;
        position: absolute;
        left: -2px;
        top: 50%;
        margin-top: -2px;
      }
      
      &:first-child {
        padding-left: 0;
        margin-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 848px;
    flex-wrap: wrap;
    margin: 0 auto;
  }
`

export const PostFooterStyle = styled.div`
  ${tw`bg-black-20 text-center py-2 px-4`}
  
  * {
    ${tw`text-xs`}
  }
  
  img {
    ${tw`mx-auto my-2`}
  }
`

export const FooterHomeWrapStyle = styled.footer`
  ${tw`bg-black text-white p-6 text-center`}

  ul {
    list-style: none;
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 0;
    ${tw`text-center md:flex md:justify-center`}

    li {
      ${tw`text-sm mb-2 relative md:text-base`};

      @media screen and (min-width: 768px) {
        padding-left: 24px;
        margin-left: 24px;
        &:before {
          content: '';
          border-radius: 100%;
          width: 4px;
          height: 4px;
          background-color: white;
          display: block;
          position: absolute;
          left: -2px;
          top: 50%;
          margin-top: -2px;
        }
        &:first-child {
          padding-left: 0;
          margin-left: 0;
          
          &:before {
            content: none;
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      max-width: 848px;
      flex-wrap: wrap;
      margin: 0 auto;
    }
  }
`
