import React from 'react'
import { RankedPositionWrapper, RankingWrap } from './ranking.styles'
import { AwardIcon, FilledWarningDownIcon, SpinnerIcon } from 'assets'
import { Tabs } from 'ui/components/tabs'
import { rankingViewModel } from 'viewModels'
import { RankingTexts } from 'uiConstants'
import { Loading } from 'components'

export function Ranking (): JSX.Element {
  const {
    loading,
    rankingList,
    storeRanking,
    allRewards,
    getList,
    handleOnClick,
    toggleState,
    setInitialPage,
    initialPage,
    id,
    handleOnChange,
    rankingCalculationType,
    loadingTabsDate,
    dynamicName
  } = rankingViewModel()
  return (
    <>
      {storeRanking && (
        <RankingWrap>
          <h5 className='uppercase text-center md:mb-10 md:text-2xl'>{RankingTexts.title}</h5>
          <RankedPositionWrapper>
            <span className='font-bold md:font-normal'>{RankingTexts.position}</span>
            <div
              className={`${
                storeRanking.winner ? 'border-green' : 'border-yellow'
              } border-2 rounded-full w-20 h-20 flex justify-center items-center my-4`}
            >
              <span className='font-bold text-lg'>{storeRanking?.ranking === null ? '-' : storeRanking?.ranking}</span>
            </div>
            <div
              className={`${
                storeRanking?.winner ? 'bg-green' : 'bg-yellow'
              } flex flex-col justify-center items-center w-full rounded-lg p-1`}
            >
              <span className='flex items-center gap-2 text-xs'>
                {storeRanking?.winner
                  ? (
                    <AwardIcon />
                    )
                  : (
                    <FilledWarningDownIcon className='w-8' />
                    )}
              </span>
              {storeRanking?.message &&
                (
                <span className='font-bold text-center'>{
                    <span>{storeRanking?.message}</span>
                }</span>
                )}
            </div>
          </RankedPositionWrapper>
          {loadingTabsDate
            ? <div><SpinnerIcon/></div>
            : <Tabs
                rankingList={rankingList}
                allRewards={allRewards}
                getList={getList}
                handleOnClick={handleOnClick}
                toggleState={toggleState}
                setInitialPage={setInitialPage}
                initialPage={initialPage}
                id={id}
                handleOnChange={handleOnChange}
                rankingCalculationType={rankingCalculationType}
                dynamicName={dynamicName}
            />
          }
        </RankingWrap>
      )}
      {loading && <Loading />}
    </>
  )
}
