import React from 'react'
import { WrapDynamicList, CardDynamic, SpinnerWrapStyle, GrayTag, MessageWarning } from './home.styles'
import { homeViewModel } from 'viewModels'
import { WarningDownIcon } from 'assets'
import { HomeTexts } from 'uiConstants'
import { Helmet } from 'react-helmet'
import Favicon from '../../assets/images/default-favicon.png'

export function Home (): JSX.Element {
  const { dynamicsList, handleButton, loading, userInfo } = homeViewModel()

  return (
    <div className='md:mt-4'>
      <Helmet>
        <title>Dinámicas trade</title>
        <link rel='icon' href={ Favicon } sizes='16x16' />
      </Helmet>
      {userInfo?.name &&
        <div className='text-center md:text-right md:px-8 md:mb-20'>
          <span className='text-2xl font-medium mb-2 block md:mb-0 uppercase'>HOLA {userInfo.name_owner}</span>
          <h6 className='mb-2 md:mb-1'>{userInfo.name}</h6>
          <div className='mb-14 md:flex md:items-center md:justify-end md:mb-0'>
            <GrayTag>{userInfo.client_type_id}</GrayTag>
            <span className='block text-xs md:inline-block md:text-base'>Código BEES: <span className='font-medium md:font-semibold'>{userInfo.id}</span></span>
          </div>
        </div>
      }
      {dynamicsList.length > 0
        ? <div>
          <h5 className='text-center block mb-2'>
            {userInfo?.name && <>TUS</>} {HomeTexts.title}
          </h5>
          <p className='text-center block mb-6 md:mb-4 block'>
            {userInfo?.name
              ? <>{HomeTexts.availableDynamicsAuth}</>
              : <>{HomeTexts.availableDynamics}</>
            }
          </p>
          <WrapDynamicList>
            {dynamicsList.map(({ images_mobile: imageMobile, short_description: shortDescription, id, name, slug, brand, store_accept_term: storesAccept }) => {
              return (
                <CardDynamic key={id}>
                  {imageMobile &&
                    <img className='mb-4 rounded-lg w-full m-auto' src={imageMobile[0]?.file_path_image} alt={name} />
                  }
                  <div className='text-sm mb-8 md:text-base' dangerouslySetInnerHTML={ { __html: shortDescription } }>
                  </div>
                  <div className='content-button'>
                    <button onClick={() => {
                      handleButton(slug, brand.slug, storesAccept)
                    }}>
                      {HomeTexts.ctaDynamic}
                    </button>
                  </div>
                </CardDynamic>
              )
            })}
          </WrapDynamicList>
        </div>
        : <MessageWarning>
          <div>
            <WarningDownIcon />
            <h5>{HomeTexts.titleNotAvailable}</h5>
            {userInfo?.name
              ? <p>{HomeTexts.descriptionNotAvailableAuth}</p>
              : <p>{HomeTexts.descriptionNotAvailable}</p>
            }
          </div>
        </MessageWarning>
      }
      {loading &&
        <SpinnerWrapStyle>
          Cargando la dinamica
        </SpinnerWrapStyle>
      }
    </div>
  )
}
