import { useState } from 'react'
import { dynamicUseCase } from 'domain/useCases'
import { dynamicImp } from 'data/implementations'
import { type FooterHelper } from './footer.interfaces'
import { gtmFooterLinks } from './footer.datalayer'

export function footerHelper (): FooterHelper {
  const [showTcModal, setShowTcModal] = useState<boolean>(false)
  const { getTheme, getBrand, getDynamic } = dynamicUseCase(dynamicImp())
  const { name: dynamicName } = getDynamic()
  const { file_path_logo_sm: logo } = getTheme()
  const { data: { footer_links: footerLinks } } = getBrand()

  function handleTcModal (): void {
    gtmFooterLinks(dynamicName, 'Términos y condiciones')
    setShowTcModal(!showTcModal)
  }

  function handleGtm (name: string): void {
    gtmFooterLinks(dynamicName, name)
  }

  return {
    logo,
    footerLinks,
    handleTcModal,
    showTcModal,
    handleGtm
  }
}
