import { useState } from 'react'
import { dynamicUseCase, sessionUseCase } from 'domain/useCases'
import { dynamicImp, sessionImp } from 'data/implementations'
import { yupResolver } from '@hookform/resolvers/yup'
import { boolean, object } from 'yup'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { type AgeGateModalHelper, type FieldValue } from './ageGateModal.interface'
import { useCustomEffect } from 'hooks'
import { ExternalUrls } from 'uiConstants'
import { gtmAgeGateYes, gtmAgeGateNo, gtmAgeGateRemember } from './ageGateModal.datalayer'

const Schema = object({
  ageGate: boolean()
})

export function ageGateModalHelper (): AgeGateModalHelper {
  const [showModal, setShowModal] = useState<boolean>(true)
  const { getTheme, getDynamic } = dynamicUseCase(dynamicImp())
  const { getSession, updateAgeGateStorage } = sessionUseCase(sessionImp())
  const { id: dynamicId, name: dynamicName } = getDynamic()
  const { ageGate } = getSession()
  // eslint-disable-next-line

  const { file_path_logo_sm: logo } = getTheme()

  const { control, handleSubmit, watch } = useForm<FieldValue>({
    mode: 'onChange',
    // @ts-expect-error
    resolver: yupResolver(Schema),
    defaultValues: {
      ageGate: false
    }
  })
  const ageGateWatch = watch('ageGate')

  useCustomEffect(() => {
    if (sessionStorage.getItem('dynamicId') === String(dynamicId)) {
      setShowModal(false)
    } else if (ageGate?.includes(dynamicId)) {
      setShowModal(false)
    } else {
      setShowModal(true)
    }
  }, [])

  useCustomEffect(() => {
    if (ageGateWatch) {
      gtmAgeGateRemember(dynamicName)
    }
  }, [ageGateWatch])

  const onSubmit: SubmitHandler<FieldValue> = (data) => {
    if (data.ageGate) {
      setShowModal(false)
      updateAgeGateStorage(dynamicId)
      sessionStorage.removeItem('dynamicId')
    } else {
      setShowModal(false)
      sessionStorage.setItem('dynamicId', String(dynamicId))
    }
    gtmAgeGateYes(dynamicName)
  }

  function handleNotAgeGate (): void {
    gtmAgeGateNo(dynamicName)
    window.location.replace(ExternalUrls.ageGate)
  }

  return {
    logo,
    control,
    handleSubmit,
    onSubmit,
    showModal,
    handleNotAgeGate
  }
}
