import { type DynamicRepository } from 'domain/repositories'
import { type DynamicModel, type DynamicsListModel } from 'domain/models'
import { postDynamicService, getDynamicService, getAllDynamicsService } from 'data/services'
import { brandAction, dynamicAction, rankingDateAction, clearBranAction, clearDynamicAction } from 'data/redux/actions'
import { brandThemeSelector, dynamicInfoSelector, brandInfoSelector, rankingDateSelector } from 'data/redux/selectors'

export function dynamicImp (): DynamicRepository {
  return {
    async dynamicData (params): Promise<DynamicModel> {
      const response = await postDynamicService(params)
      dynamicAction({
        ...response.data.data
      })
      brandAction({
        name: response.data.data.brand.name,
        id: response.data.data.brand.id,
        data: response.data.data.brand.data,
        theme: response.data.data.brand.theme,
        slug: response.data.data.brand.slug
      })
      rankingDateAction({
        raking_published_end_date: response.data.data.raking_published_end_date,
        raking_published_start_date: response.data.data.raking_published_start_date
      })
      return response.data
    },
    async getDynamicHomeData (dynamicId: number): Promise<DynamicModel> {
      const response = await getDynamicService(dynamicId)
      return response.data
    },
    getTheme () {
      return brandThemeSelector()
    },
    getDynamic () {
      return dynamicInfoSelector()
    },
    getBrand () {
      return brandInfoSelector()
    },
    getRankingDate () {
      return rankingDateSelector()
    },
    async getDynamicsList (): Promise<DynamicsListModel> {
      const response = await getAllDynamicsService()
      return response.data
    },
    clearBrand () {
      clearBranAction()
    },
    clearDynamic () {
      clearDynamicAction()
    }
  }
}
