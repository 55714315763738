import tw from 'twin.macro'
import styled from 'styled-components'

export const SelectFieldWrapStyle = styled.div`
  ${tw`mb-6`}
`

export const SelectFieldCustomWrapStyle = styled.div`
  ${tw`
    bg-white
    text-black
    border border-solid border-black-60
    rounded
    w-full
    relative
  `};
  padding: 9.5px 1rem;
`

export const SelectFieldCustomHeaderStyle = styled.div`
  ${tw`relative cursor-pointer`}

  svg {
    ${tw`absolute top-2/4 right-0`}
    margin-top: -4px;
  }
`

export const SelectFieldCustomBodyStyle = styled.div`
  ${tw`
    absolute left-0
    bg-white 
    w-full
    rounded
    border border-solid border-black-60
    z-10
  `}
  top: 45px;

  > div {
    ${tw`
      cursor-pointer
      border-black-20 border-solid border-b border-l-2 border-r-2
      py-2 px-4
    `}

    &:first-child {
      ${tw`rounded-t border-t-2`}
    }
    &:last-child {
      ${tw`rounded-b border-b-2`}
    }
    &.active {
      background-color: ${({ theme }) => theme.color_button};
      
      span {
        color: ${({ theme }) => theme.color_button_text};
      }
    }
    span {
      ${tw`text-base`}
    }
    &:hover {
      ${tw``}
    }
  }
`

export const SelectFieldLabelStyle = styled.label`
  ${tw`
    font-bold
    block
    mb-2
  `};
`
