import { dynamicUseCase } from 'domain/useCases'
import { dynamicImp } from 'data/implementations'
import { type RegisterTermsModalHelper } from './registerTermsModal.interfaces'

export function registerTermsModalHelper (): RegisterTermsModalHelper {
  const { getDynamic } = dynamicUseCase(dynamicImp())
  const { legal_term: { content_main: legalTerm } } = getDynamic()

  return {
    legalTerm
  }
}
