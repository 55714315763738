export enum PublicPath {
  home = '/',
  dynamicUrl = '/[:brand]/[:dynamic]',
  login = '/iniciar-sesion',
  register = '/registro',
}

export enum PrivatePath {
  loggedHome = '/inicio',
  rewards = '/premios',
  ranking = '/ranking',
  rankingURL = '/:reward/:page'
}

export enum ExternalUrls {
  ageGate = 'https://www.tapintoyourbeer.com/agegate?destination='
}
