import { type StoreRepository } from 'domain/repositories'
import { storeSelector } from 'data/redux/selectors'
import { type LoginModel, type RequestSuccessModel } from 'domain/models'
import { loginService, logoutService, registerService } from 'data/services'
import { clearSessionAction, clearStoreInfo, saveSessionAction, saveStoreInfo } from 'data/redux/actions'

export function storeImp (): StoreRepository {
  return {
    async logout (): Promise<RequestSuccessModel> {
      const response = await logoutService()
      clearSessionAction()
      clearStoreInfo()
      sessionStorage.setItem('dynamicId', '')
      return response.data
    },
    async login (params): Promise<LoginModel> {
      const response = await loginService(params)
      const { token, store, needsSelectStore, termsAcceptance } = response.data.data
      if (!needsSelectStore) {
        saveStoreInfo(store)
        saveSessionAction({ token, termsAcceptance })
      }
      return response.data
    },
    getStore () {
      return storeSelector()
    },
    async registerStore (params): Promise<LoginModel> {
      const response = await registerService(params)
      const { token, store } = response.data.data
      saveStoreInfo(store)
      saveSessionAction({ token })
      return response.data
    }
  }
}
