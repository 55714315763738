import React from 'react'
import { LoginTexts } from 'uiConstants'
import { Button, CheckboxField, Modal } from 'components'
import { AgeGateModalButtonsStyle, AgeGateModalLogoWrapStyle, AgeGateModalWrap } from './ageGateModal.styles'
import { ageGateModalHelper } from './ageGateModal.helper'

export function AgeGateModal (): JSX.Element {
  const { handleSubmit, onSubmit, logo, control, showModal, handleNotAgeGate } = ageGateModalHelper()

  return (
    <Modal showModal={showModal} className='agegate-modal'>
      <AgeGateModalWrap>
        <AgeGateModalLogoWrapStyle>
          <img src={ logo } alt="logo" className='mx-auto' width={90} />
        </AgeGateModalLogoWrapStyle>
        <h5 className='mb-6'>{ LoginTexts.ageGateTitle }</h5>
        {/* eslint-disable-next-line */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <AgeGateModalButtonsStyle>
            <Button type='submit'>
              SÍ
            </Button>
            <Button type='button' onAction={ handleNotAgeGate }>
              NO
            </Button>
          </AgeGateModalButtonsStyle>
          <p className='mb-6 text-sm md:text-base'>
            {LoginTexts.ageGateDescription}
          </p>
          <CheckboxField
            control={ control }
            name='ageGate'
            label={LoginTexts.ageGateCheckbox}
            id='ageGate'
          />
        </form>
      </AgeGateModalWrap>
    </Modal>
  )
}
