import styled from 'styled-components'
import tw from 'twin.macro'

export const WrapDynamicList = styled.section`
  @media screen and (min-width: 768px) {
    max-width: 1240px;
    ${tw`flex flex-wrap mx-auto`};
  }
`

export const GrayTag = styled.span`
  ${tw`bg-white border border-black-20 font-medium text-black-70 text-center rounded-2xl px-2 py-1 text-xs mb-2 inline-block md:text-base md:mr-4 md:mb-0`}
`

export const CardDynamic = styled.div`
  ${tw`
    rounded-2xl
    mb-6
    px-4 pt-4 pb-8
    bg-white
    relative
  `}
  border: 1px solid #F3F3F3;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.08);

  > img {

    @media screen and (min-width: 768px) {
      max-height: calc((((100vw - 4%) - 8rem) * 224) / 1240);
      min-height: calc((((100vw - 4%) - 8rem) * 224) / 1240);
    }
    @media screen and (min-width: 1240px) {
      max-height: 224px;
      min-height: 224px;
    }
  }
  @media screen and (min-width: 768px) {
    max-width: 32%;
    flex: 1 32%;
    margin-right: 2%;
    ${tw`justify-start mb-4`}
    padding-bottom: 81px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .content-button {
    ${tw`text-center`}

    @media screen and (min-width: 768px) {
      ${tw`text-center absolute left-0 w-full`}
      bottom: 32px;
    }
  }
  button {
    ${tw`bg-black text-white px-6 py-3 rounded-xl`}
  }
`

export const SpinnerWrapStyle = styled.div`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
`

export const MessageWarning = styled.div`
  ${tw`text-center `}

  svg {
    ${tw`mx-auto mb-4`}
  }

  h5 {
    ${tw`mb-4`}
  }

  > div {
    ${tw`mx-auto`}
    max-width: 403px;
  }
`
