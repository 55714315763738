import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'

export function gtmRegister (dynamicName: string, contact: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Register',
      action: 'Submit',
      label: 'LoginAPI',
      interaction: 'true',
      additionalData: {
        campaign_description: dynamicName,
        component_name: 'Continuar',
        contact
      }
    })
  )
}

export function gtmRegisterSuccess (dynamicName: string, userId: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Register',
      action: 'Success',
      label: 'LoginAPI',
      interaction: 'true',
      additionalData: {
        campaign_description: dynamicName,
        userUid: userId
      }
    })
  )
}

export function gtmRegisterError (dynamicName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Register',
      action: 'Error',
      label: 'LoginAPI',
      interaction: 'true',
      additionalData: {
        campaign_description: dynamicName
      }
    })
  )
}

export function gtmRegisterCloseTCModal (dynamicName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Content',
      action: 'Content',
      label: 'Terminos y condiciones',
      interaction: 'true',
      additionalData: {
        campaign_description: dynamicName
      }
    })
  )
}

export function gtmErrorRegisterModal (dynamicName: string, errorDescription: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Register',
      action: 'Error',
      label: 'LoginApi',
      interaction: 'true',
      additionalData: {
        error_description: errorDescription,
        campaign_description: dynamicName
      }
    })
  )
}
