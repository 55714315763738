import styled from 'styled-components'
import tw from 'twin.macro'

export const ModalWrapStyle = styled.div`
  ${tw`
    bg-black
    flex justify-center items-center
    w-full h-full
    px-6 py-8
    w-full h-full
    fixed top-0 left-0
    z-50
  `};

  > div {
    ${tw`
      bg-white
      rounded-2xl
      flex justify-center items-center
      w-full h-full
      p-4
      relative
    `};

    @media screen and (min-width: 768px) {
      max-width: 676px;
      height: auto;
    }
    
    > div {
      ${tw`max-h-full`}
    }
  }
  &.bg-transparent {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.tc-modal {
    > div {
      @media screen and (min-width: 768px) {
        max-width: 1280px;
        width: 100%;
        max-height: 100%;
        height: 100%
      }
    }
  }

  &.agegate-modal {
    > div {
      @media screen and (min-width: 768px) {
        padding-top: 96px;
      }
    }
  }


`
