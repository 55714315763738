import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { type SelectFieldProps } from './selectField.interfaces'
import { useController } from 'react-hook-form'
import { ArrowDownIcon } from 'assets'
import { SelectFieldWrapStyle, SelectFieldCustomWrapStyle, SelectFieldCustomHeaderStyle, SelectFieldCustomBodyStyle, SelectFieldLabelStyle } from './selectField.styles'

export function SelectField (props: SelectFieldProps): JSX.Element {
  const {
    options = [],
    valuePropName = 'value',
    labelPropName = 'label',
    placeholder,
    defaultValue,
    name,
    control,
    id,
    label,
    showKeyInLabel
  } = props
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [selectText, setSelectText] = useState<string | number>('')
  const [selectKey, setSelectKey] = useState<string | number>('')

  const {
    field: { onChange, onBlur, ref, value }
  } = useController({
    name,
    control
  })

  function handleChangeSelect (value: string | number): void {
    setSelectText(_.filter(options, { [valuePropName]: value })[0][labelPropName])
    setSelectKey(_.filter(options, { [valuePropName]: value })[0][valuePropName])
    onChange(value)
    setShowOptions(false)
  }

  useEffect(() => {
    if (options && defaultValue) {
      setSelectText(_.filter(options, { [valuePropName]: defaultValue })[0][labelPropName])
      setSelectKey(_.filter(options, { [valuePropName]: defaultValue })[0][valuePropName])
    }
    if (options && !placeholder) {
      handleChangeSelect(options[0][valuePropName])
    }
  }, [!placeholder, options])

  return (
    <SelectFieldWrapStyle>
      {label &&
        <SelectFieldLabelStyle htmlFor={id}>
          { label }
        </SelectFieldLabelStyle>
      }
      <SelectFieldCustomWrapStyle>
        <SelectFieldCustomHeaderStyle
          onClick={ () => { setShowOptions(!showOptions) } }
        >
          { selectText
            ? <span>
              {showKeyInLabel === 'before' && `${selectKey} - `}
              { selectText }
              {showKeyInLabel === 'after' && ` - ${selectKey}`}
            </span>
            : <span>
              { placeholder
                ? <>
                  {placeholder}
                </>
                : <>
                  {options[0][labelPropName]}
                </>
              }
            </span>
          }
          <ArrowDownIcon />
        </SelectFieldCustomHeaderStyle>
        {showOptions &&
            <SelectFieldCustomBodyStyle>
              {_.map(options, (item, index) => {
                return (
                  <div key={index}
                       onClick={ () => { handleChangeSelect(item[valuePropName]) } }
                       className={selectText === item[labelPropName] ? 'active' : ''}
                  >
                <span>
                  {showKeyInLabel === 'before' && `${item[valuePropName]} - `}
                  {item[labelPropName]}
                  {showKeyInLabel === 'after' && ` - ${item[valuePropName]}`}
                </span>
                  </div>
                )
              })}
            </SelectFieldCustomBodyStyle>
        }
      </SelectFieldCustomWrapStyle>
      <select
        // {...props}
        ref={ref}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className='hidden'
        id={id}
      >
        {_.map(options, (item, index) => {
          return (
            <option
              value={item[valuePropName]}
              key={index}
            >
              {item[labelPropName]}
            </option>
          )
        })}
      </select>
    </SelectFieldWrapStyle>
  )
}
