import styled from 'styled-components'
import tw from 'twin.macro'

export const LoginErrorModalWrap = styled.div`
  ${tw`text-center md:py-6`}

  h5 {
    color: ${({ theme }) => theme.color_title};
  }
`
