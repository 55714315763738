import axios, { type AxiosResponse, type AxiosError } from 'axios'
import { type DynamicParams } from 'domain/repositories'
import { type DynamicModel, type DynamicsListModel } from 'domain/models'
import { ServicesPaths } from 'data/constants'

export async function postDynamicService (params: DynamicParams): Promise<AxiosResponse<DynamicModel, AxiosError>> {
  return await axios.post(ServicesPaths.dynamic, {
    ...params
  })
}

export async function getDynamicService (dynamicID: number): Promise<AxiosResponse<DynamicModel, AxiosError>> {
  return await axios.get(`${ServicesPaths.dynamicHome}/${dynamicID}`)
}

export async function getAllDynamicsService (): Promise<AxiosResponse<DynamicsListModel, AxiosError>> {
  return await axios.get(`${ServicesPaths.allDynamics}`)
}
