import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function FilledWarningDownIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#000', className } = props

  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16ZM8.69572 10.2493C9.05615 10.2493 9.42123 9.98085 9.47387 9.40656L9.89563 4.04984C9.89563 3.50501 9.28951 3 8.72652 3C8.16353 3 7.5 3.48301 7.5 4.0286L7.97094 9.45788C8.08751 10.0387 8.35326 10.1846 8.69572 10.2493ZM7.67782 11.9794C7.67782 11.4158 8.13479 10.9595 8.69777 10.9595C9.26137 10.9595 9.71773 11.4164 9.71773 11.9794C9.71773 12.543 9.26137 13 8.69777 13C8.13418 13 7.67782 12.543 7.67782 11.9794Z'
        fill={primaryColor}
      />
    </svg>
  )
}
