import tw from 'twin.macro'
import styled from 'styled-components'
import { type LoadingStyle } from './loading.interfaces'

export const LoadingWrapStyle = styled.div<LoadingStyle>`
  ${tw`fixed w-full h-full flex justify-center items-center top-0 left-0`}
  background-color: ${({ theme }) => theme.color_button};
  z-index: 1000;

  .wrapper-spinner {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    align-items: center;
    justify-content: center;
  }
  .spinner {
    animation: rotate 2s infinite;
    height: 50px;
    width: 50px;
  }
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }
  .spinner:before {
    animation: ball1 2s infinite;
    background-color: ${({ theme }) => theme.color_button_text};
    box-shadow: ${({ iconColor }) => `30px 0 0 ${iconColor}`};
    margin-bottom: 10px;
  }
  .spinner:after {
    animation: ball2 2s infinite;
    background-color: ${({ theme }) => theme.color_button_text};
    box-shadow: ${({ iconColor }) => `30px 0 0 ${iconColor}`};
  }
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg) scale(0.8);
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% {
      -webkit-transform: rotate(360deg) scale(1.2);
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotate(720deg) scale(0.8);
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }
  @keyframes ball1 {
    0% {
      box-shadow: ${({ iconColor }) => `30px 0 0 ${iconColor}`};
    }
    
    50% {
      box-shadow: ${({ iconColor }) => `0 0 0 ${iconColor}`};
      margin-bottom: 0;
      -webkit-transform: translate(15px, 15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: ${({ iconColor }) => `30px 0 0 ${iconColor}`};
      margin-bottom: 10px;
    }
  }
  @keyframes ball2 {
    0% {
      box-shadow: ${({ iconColor }) => `30px 0 0 ${iconColor}`};
      
    }
    50% {
      box-shadow: ${({ iconColor }) => `0 0 0 ${iconColor}`};
      margin-top: -20px;
      -webkit-transform: translate(15px, 15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: ${({ iconColor }) => `30px 0 0 ${iconColor}`};
      margin-top: 0;
    }
  }
`
