import React from 'react'

export function ChevronLeftIcon (): JSX.Element {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.7485 6.35159C15.9735 6.57662 16.0999 6.88179 16.0999 7.19999C16.0999 7.51818 15.9735 7.82335 15.7485 8.04839L11.7969 12L15.7485 15.9516C15.9671 16.1779 16.0881 16.481 16.0853 16.7957C16.0826 17.1103 15.9564 17.4113 15.7339 17.6338C15.5114 17.8563 15.2105 17.9825 14.8958 17.9852C14.5812 17.9879 14.2781 17.867 14.0517 17.6484L9.25173 12.8484C9.02677 12.6234 8.90039 12.3182 8.90039 12C8.90039 11.6818 9.02677 11.3766 9.25173 11.1516L14.0517 6.35159C14.2768 6.12662 14.5819 6.00024 14.9001 6.00024C15.2183 6.00024 15.5235 6.12662 15.7485 6.35159Z'
        fill='#0C2340'
      />
    </svg>
  )
}
