import { createReducer } from './createReducer'
import { ActionsTypes } from '../actions/actions.types'
import { type AnyAction } from 'redux'

const sessionState = {
  token: '',
  dynamicId: '',
  ageGate: [],
  termsAcceptance: {
    previousData: {
      email: '',
      lastname: '',
      name: '',
      phone: ''
    }
  }
}

export const session = createReducer(sessionState, {
  [ActionsTypes.SESSION] (state: Record<string, any>, action: AnyAction) {
    const { payload } = action
    return {
      ...state,
      ...payload
    }
  },
  [ActionsTypes.AGEGATE_SAVE] (state: Record<string, any>, action: AnyAction) {
    const { payload } = action
    return {
      ...state,
      ageGate: [...state?.ageGate, payload]
    }
  },
  [ActionsTypes.CLEAR_SESSION] (state: Record<string, any>) {
    return {
      ageGate: [...state?.ageGate],
      token: '',
      dynamicId: '',
      termsAcceptance: {
        previousData: {
          email: '',
          lastname: '',
          name: '',
          phone: ''
        }
      }
    }
  }
})
