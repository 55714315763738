import React, { useMemo } from 'react'
import { sessionImp } from 'data/implementations'
import { sessionUseCase } from 'domain/useCases'
import { Navigate } from 'react-router-dom'
import { PublicPath } from 'uiConstants'

interface PrivateRoutesProps {
  children: JSX.Element
}

export function PrivateRoutes (props: PrivateRoutesProps): JSX.Element {
  const { getSession } = sessionUseCase(sessionImp())
  const { token } = getSession()
  const { children } = props

  const path = useMemo<string[]>(() => {
    const location = typeof window !== 'undefined' && window.location
    return location ? location.pathname.split('/') : []
  }, [])

  if (!token) {
    return <Navigate to={`/${path[1]}/${path[2]}${PublicPath.login}`} />
  }

  return (
    <div className='md:mt-24'>
     { children }
    </div>
  )
}
