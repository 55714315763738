import { createReducer } from './createReducer'
import { ActionsTypes } from '../actions/actions.types'
import { type AnyAction } from 'redux'

const dynamicState = {
  id: 0,
  name: '',
  description: '',
  legal_term: {
    id: 0,
    content_main: '',
    content_checkbox_1: ''
  },
  images: [],
  raking_published_end_date: new Date(),
  raking_published_start_date: new Date(),
  execution_start_date: new Date(),
  execution_end_date: new Date()
}

export const dynamic = createReducer(dynamicState, {
  [ActionsTypes.DYNAMIC] (state: Record<string, any>, action: AnyAction) {
    const { payload } = action
    return {
      ...state,
      ...payload
    }
  },
  [ActionsTypes.CLEAR_DYNAMIC] () {
    return {
      ...dynamicState
    }
  }
})

const themeState = {
  file_path_logo_sm: '',
  file_path_logo_lg: '',
  file_path_favicon: '',
  size_x_pixel_logo_sm: 0,
  size_x_pixel_logo_lg: 0,
  size_y_pixel_logo_sm: 0,
  size_y_pixel_logo_lg: 0,
  color_title: '',
  color_icon: '',
  color_bg: '',
  color_bg_home: '',
  color_button: '',
  color_button_text: '',
  color_footer_bg: '',
  color_footer_text: ''
}

const brandState = {
  id: 0,
  name: '',
  data: {
    footer_links: []
  },
  theme: themeState
}

export const brand = createReducer(brandState, {
  [ActionsTypes.BRAND] (state: Record<string, any>, action: AnyAction) {
    const { payload } = action
    return {
      ...state,
      ...payload
    }
  },
  [ActionsTypes.CLEAR_BRAND] () {
    return {
      ...brandState
    }
  }
})

const rankingDateState = {
  raking_published_end_date: new Date(),
  raking_published_start_date: new Date()
}

export const rankingDate = createReducer(rankingDateState, {
  [ActionsTypes.RANKING_DATE] (state: Record<string, any>, action: AnyAction) {
    const { payload } = action
    return {
      ...state,
      ...payload
    }
  }
})
