import { type SessionRepository } from 'domain/repositories'

export function sessionUseCase (repository: SessionRepository): SessionRepository {
  return {
    getSession () {
      return repository.getSession()
    },
    updateDynamicId (id) {
      repository.updateDynamicId(id)
    },
    clearSession () {
      repository.clearSession()
    },
    updateAgeGateStorage (dynamicId) {
      repository.updateAgeGateStorage(dynamicId)
    }
  }
}
