import TagManager from 'react-gtm-module'
import { datalayerHelper } from 'helper'

export function gtmFooterLinks (dynamicName: string, componentName: string): void {
  TagManager.dataLayer(
    datalayerHelper({
      type: 'GAEvent',
      category: 'Content',
      action: 'Content',
      label: 'Footer',
      interaction: 'true',
      additionalData: {
        component_name: componentName,
        campaign_description: dynamicName
      }
    })
  )
}
