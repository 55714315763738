import { createGlobalStyle } from 'styled-components'
import { type ThemeEntity } from 'domain/entities'
import tw from 'twin.macro'

const GlobalStyle = createGlobalStyle<{ theme: ThemeEntity }>`
  html {
    height: 100%;
  }
  body {
    background-color: ${({ theme }) => theme.color_bg};
    height: 100%;
  }
  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    color: ${({ theme }) => theme.color_title};
    font-weight: 800;
  }
  h1 {
    ${tw`text-h1-sm`}
  }
  h2 {
    ${tw`text-h2-sm`}
  }
  h3 {
    ${tw`text-h3-sm`}
  }
  h4 {
    ${tw`text-h4-sm`}
  }
  h5 {
    ${tw`text-h5-sm md:text-h5-lg`}
  }
  h6 {
    ${tw`text-h6-sm`}
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  main {
    flex-grow: 1;
    
    .NavWrapStyle {
      ${tw`block md:hidden`}
    }
  }
  
  .swiper-initialized {
    padding-bottom: 45px;
  }
  
  .swiper-pagination {
    ${tw`
      absolute
    `}
    
    .swiper-pagination-bullet {
      border-color: ${({ theme }) => theme.color_button};
      ${tw`
        border border-solid
        bg-transparent
        opacity-100
      `}
      margin: 0 12px!important;
      height: 16px;
      width: 16px;
      
      &-active {
        background-color: ${({ theme }) => theme.color_button};
      }
    }
  }
  
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }

  ol {
    list-style: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  
  ul {  
    list-style: initial;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  .spinnerIcon circle {
    stroke: ${({ theme }) => theme.color_button};
  }
`
export default GlobalStyle
