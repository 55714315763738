import { useState, useMemo } from 'react'
import { dynamicUseCase, sessionUseCase } from 'domain/useCases'
import { dynamicImp, sessionImp } from 'data/implementations'
import { type LayoutViewModel } from './viewModel.interfaces'
import { useNavigation, useCustomEffect } from 'hooks'
import { PublicPath, PrivatePath } from 'uiConstants'

export function layoutViewModel (): LayoutViewModel {
  const { getSession } = sessionUseCase(sessionImp())
  const { dynamicId } = getSession()
  const { dynamicData, getTheme, getDynamic, getBrand } = dynamicUseCase(dynamicImp())
  const [loading, setLoading] = useState<boolean>(true)
  const [dynamicError, setDynamicError] = useState<boolean>(false)
  const [dynamicErrorType, setDynamicErrorType] = useState<'date' | 'notFound'>('notFound')
  const { getCurrentPathName, replaceAndNavigateTo } = useNavigation()
  const theme = getTheme()
  const { name: dynamicName } = getDynamic()
  const { name: brandName, id: brandId } = getBrand()

  const pathName = useMemo<string[]>(() => {
    const location = getCurrentPathName()
    return location ? location.split('/') : []
  }, [])

  useCustomEffect(() => {
    if (pathName.length > 2) {
      dynamicData({
        dynamic_slug: pathName[2],
        brand_slug: pathName[1]
      }).then(response => {
        if (dynamicId === response.data.id) {
          if (pathName[3] === 'iniciar-sesion' || pathName[3] === 'registro') {
            replaceAndNavigateTo(`${pathName[1]}/${pathName[2]}${PrivatePath.loggedHome}`)
          } else if (pathName.length === 3 || pathName[3] === '') {
            replaceAndNavigateTo(`${pathName[1]}/${pathName[2]}${PrivatePath.loggedHome}`)
          }
        } else if (pathName[3] !== 'registro') {
          replaceAndNavigateTo(`${pathName[1]}/${pathName[2]}${PublicPath.login}`)
        }
        const today = new Date()
        const starDate = new Date(response.data.published_start_date)
        const endDate = new Date(response.data.published_end_date)
        if (!(today >= starDate) || !(today <= endDate)) {
          setDynamicError(true)
          setDynamicErrorType('date')
        }
        setLoading(false)
      }).catch(() => {
        replaceAndNavigateTo('/')
        setLoading(false)
      })
    } else {
      replaceAndNavigateTo('/')
      setLoading(false)
    }
  }, [])

  useCustomEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [getCurrentPathName()])

  return {
    theme,
    loading,
    dynamicName,
    brandName,
    dynamicError,
    dynamicErrorType,
    brandId
  }
}
