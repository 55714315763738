import React from 'react'
import { AgeGateModal, TextField, Button, SelectField, Loading, LoginErrorModal } from 'components'
import { loginViewModel } from 'viewModels'
import { LoginTexts } from 'uiConstants'
import { LoginDescriptionWrap, LoginFormWrap, LoginSliderWrapStyle, LoginTitleWrap, FormAndTitleWrap } from './login.styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { type TypeOptions } from 'domain/entities'
import { useWindowDimensions } from 'hooks'

export function Login (): JSX.Element {
  const {
    control,
    handleSubmit,
    onSubmit,
    dynamicDescription,
    identifications,
    isValid,
    loading,
    modalError,
    handleCloseModalError,
    storeList,
    images,
    dynamicName,
    errors,
    imagesMobile
  } = loginViewModel()
  const { width } = useWindowDimensions()

  return (
    <div>
      <LoginTitleWrap>
        <h5><span>Bienvenido a </span>{ dynamicName }</h5>
      </LoginTitleWrap>
      <LoginSliderWrapStyle>
        {images.length > 0 && (width && width > 768) && <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true
          }}
          modules={[Pagination]}
        >
          {images.map(({ file_path_image: imagePath }, index) => {
            return (
              <SwiperSlide key={ index }>
                <img src={ imagePath } alt={`banner-${index}`} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        }
        {imagesMobile?.length > 0 && (width && width <= 768) && <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true
          }}
          modules={[Pagination]}
        >
          {imagesMobile.map(({ file_path_image: imagePath }, index) => {
            return (
              <SwiperSlide key={ index }>
                <img src={ imagePath } alt='banner' />
              </SwiperSlide>
            )
          })}
        </Swiper>
        }
      </LoginSliderWrapStyle>
      <FormAndTitleWrap>
        <LoginDescriptionWrap>
          <h5 className='mb-4 text-center'> { LoginTexts.howDynamicWork } </h5>
          <div className="md:text-base" dangerouslySetInnerHTML={ { __html: dynamicDescription } }></div>
        </LoginDescriptionWrap>
        {/* eslint-disable-next-line */}
        <LoginFormWrap onSubmit={handleSubmit(onSubmit)} className='px-2'>
          <h5 className='mb-6 text-center'> { LoginTexts.loginTitle } </h5>
          {identifications &&
            <SelectField
              valuePropName='id'
              labelPropName='name'
              name='identificationType'
              options={identifications}
              id='identificationType'
              control={ control }
            />
          }

          <TextField
            placeholder='Digita tu código de cliente'
            name='userId'
            id='userId'
            control={ control }
            errorMessage={errors?.userId?.message}
          />

          {storeList as TypeOptions[] &&
            <SelectField
              valuePropName='id'
              labelPropName='name'
              name='storeId'
              options={storeList as TypeOptions[]}
              id='storeId'
              control={ control }
              label={ LoginTexts.selectStoreLabel }
              showKeyInLabel='before'
            />
          }
          <div className='text-center'>
            <Button type='submit' size='large' disabled={ !isValid }>
              { LoginTexts.loginButton }
            </Button>
          </div>
        </LoginFormWrap>
      </FormAndTitleWrap>
      <AgeGateModal />
      <LoginErrorModal
        showModal={modalError}
        handleCloseModal={handleCloseModalError}
      />
      { loading && <Loading /> }
    </div>
  )
}
