import { combineReducers } from '@reduxjs/toolkit'
import * as exampleReducer from './example.reducer'
import * as dynamicReducer from './dynamic.reducer'
import * as storeReducer from './store.reducer'
import * as sessionReducer from './session.reducer'

export default combineReducers({
  ...exampleReducer,
  ...dynamicReducer,
  ...storeReducer,
  ...sessionReducer
})
