import styled from 'styled-components'
import tw from 'twin.macro'

export const TextFieldWrapStyle = styled.div`
  ${tw`mb-6`}
`

export const TextFieldLabelStyle = styled.label`
  ${tw`
    font-bold
    block
    mb-2
  `};
`

export const TextFieldInputStyle = styled.input`
  ${tw`
    text-black
    border border-solid border-black-60
    rounded
    py-3 px-4
    w-full
  `};
  line-height: 19.36px;

  &::placeholder {
    ${tw`text-black-70`}
  }
  
  &:focus {
    outline-offset: 0px;
    outline: none;
  }
  
  &:disabled {
    ${tw`border-black-30 text-black-60 bg-black-10`};
  }
`

export const TextFieldErrorStyle = styled.span`
  ${tw`
    font-semibold
    text-red text-xs md:text-lg
  `};
`
