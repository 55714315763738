export const defaultData = {
  id: 0,
  slug: '',
  name: '',
  description: '',
  accumulatedPoints: 0,
  accumulatedPointsPercentage: '',
  ranking_calculation_type: 'dynamic',
  totalPoints: 0,
  legal_term: {
    id: 0,
    content_main: '',
    content_checkbox_1: ''
  },
  challenges: [
    {
      challenge_id: '',
      dynamic_id: 0,
      name: '',
      file_path_image: '',
      description: '',
      max_points: 0,
      external_link: ''
    }
  ],
  rewards: [
    {
      id: 0,
      dynamic_id: 0,
      client_type_id: '',
      name: '',
      file_path_image: '',
      ranking_initial_position: 0,
      ranking_final_position: 0
    }
  ],
  published_start_date: new Date(),
  published_end_date: new Date(),
  images: [
    {
      file_path_image: ''
    }
  ],
  owner_name: ''
}
