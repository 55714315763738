import { useSelector } from 'react-redux'
import { type StoreEntity } from '../../../domain/entities/store.entity'

interface IStore {
  store: StoreEntity
}

export function storeSelector (): StoreEntity {
  return useSelector((state: IStore) => state.store)
}
