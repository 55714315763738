import { type DynamicRepository } from 'domain/repositories'

export function dynamicUseCase (repository: DynamicRepository): DynamicRepository {
  return {
    async dynamicData (params) {
      return await repository.dynamicData(params)
    },
    getTheme () {
      return repository.getTheme()
    },
    getDynamic () {
      return repository.getDynamic()
    },
    async getDynamicHomeData (dynamicID) {
      return await repository.getDynamicHomeData(dynamicID)
    },
    getBrand () {
      return repository.getBrand()
    },
    getRankingDate () {
      return repository.getRankingDate()
    },
    clearBrand () {
      repository.clearBrand()
    },
    async getDynamicsList () {
      return await repository.getDynamicsList()
    },
    clearDynamic () {
      repository.clearDynamic()
    }
  }
}
