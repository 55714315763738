import { useMemo, useState } from 'react'
import { dynamicUseCase, sessionUseCase, storeUseCase } from 'domain/useCases'
import { dynamicImp, sessionImp, storeImp } from 'data/implementations'
import { type HeaderHelper } from './header.interfaces'
import { useNavigation } from 'hooks'
import { PublicPath } from 'uiConstants'
import { gtmHeaderMenu } from './header.datalayer'

export function headerHelper (): HeaderHelper {
  const { getTheme, getDynamic } = dynamicUseCase(dynamicImp())
  const { logout } = storeUseCase(storeImp())
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const { getSession } = sessionUseCase(sessionImp())
  const { token } = getSession()
  const { file_path_logo_sm: logo, color_icon: colorIcon } = getTheme()
  const { name } = getDynamic()
  const { replaceAndNavigateTo } = useNavigation()
  const [modalTC, setModalTC] = useState<boolean>(false)
  const location = typeof window !== 'undefined' && window.location
  const path = location ? location.pathname.split('/') : []

  const isAuthenticated = useMemo<boolean>(() => {
    if (!token) {
      return false
    } else if (path[3] === 'iniciar-sesion' || path[3] === 'registro') {
      return false
    } else {
      return true
    }
  }, [token, path])

  function handleMenuMobile (): void {
    setShowMenu(!showMenu)
  }

  function handleModalTc (): void {
    if (!modalTC) {
      gtmHeaderMenu(name, 'Terminos y condiciones')
    }
    setModalTC(!modalTC)
  }

  function handleLogout (redirectToHome: boolean): void {
    gtmHeaderMenu(name, 'Cerrar sesión')
    logout().then(() => {
      if (redirectToHome) {
        replaceAndNavigateTo(PublicPath.home)
      } else {
        replaceAndNavigateTo(`/${path[1]}/${path[2]}${PublicPath.login}`)
      }
      setShowMenu(false)
    }).catch(() => {
    })
  }

  return {
    logo,
    isAuthenticated,
    showMenu,
    handleMenuMobile,
    colorIcon,
    handleModalTc,
    modalTC,
    handleLogout
  }
}
