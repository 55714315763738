import { dynamicUseCase } from 'domain/useCases'
import { dynamicImp } from 'data/implementations'
import { type DynamicErrorModalHelper } from './dynamicError.interfaces'

export function dynamicErrorModalHelper (): DynamicErrorModalHelper {
  const { getDynamic, getBrand } = dynamicUseCase(dynamicImp())
  const { name, published_start_date: starDate, published_end_date: endDate } = getDynamic()
  const { theme } = getBrand()
  const { file_path_logo_sm: logo } = theme

  return {
    name,
    starDate,
    endDate,
    logo
  }
}
