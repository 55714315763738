import React from 'react'
import { Modal } from 'components'
import { DynamicErrorModalText } from 'uiConstants'
import { type DynamicErrorModalProps } from './dynamicError.interfaces'
import { dynamicErrorModalHelper } from './dynamicError.helper'
import { useReplaceString } from 'hooks'
import { WarningDownIcon } from 'assets'
import { DynamicErrorLogoWrapStyle } from './dynamicError.styles'

export function DynamicErrorModal (props: DynamicErrorModalProps): JSX.Element {
  const { showModal, errorType = 'notFound' } = props
  const { logo, starDate, endDate, name } = dynamicErrorModalHelper()

  return (
    <Modal showModal={ showModal }>
      <div className='text-center'>
        {errorType === 'date'
          ? <>
            <DynamicErrorLogoWrapStyle>
              <img src={ logo } className='mx-auto' width={90} />
            </DynamicErrorLogoWrapStyle>
            <h5 className='mb-6'>{ DynamicErrorModalText.title }</h5>
            <p className='text-lg mb-6'>{ useReplaceString(DynamicErrorModalText.description, {
              dynamicName: name
            }) }</p>
            <div className='text-xl mb-6' dangerouslySetInnerHTML={
              {
                __html: useReplaceString(DynamicErrorModalText.subTitle, {
                  starDateText: starDate?.toString(),
                  endDate: endDate?.toString()
                })
              }
            }>
            </div>
          </>
          : <>
            <WarningDownIcon className='mx-auto mb-6'/>
            <h5 className='text-black mb-6'> { DynamicErrorModalText.titleNotFound } </h5>
            <p className='text-lg mb-6'> { DynamicErrorModalText.descriptionNotFound } </p>
          </>
        }
      </div>
    </Modal>
  )
}
