import React from 'react'
import { NavWrapStyle } from './navPages.styles'
import { Link } from 'react-router-dom'
import { navPagesHelper } from './navPages.helper'

export function NavPages (): JSX.Element {
  const { navConfig, handleGtm, startDate, endDate } = navPagesHelper()
  const today = new Date()
  const isActive = new Date(startDate) <= today && today <= new Date(endDate)

  return (
    <NavWrapStyle>
      <ul>
        {navConfig.map((nav, index) => {
          return (
            <li key={index} className={ nav.status ? 'active' : !isActive && index === navConfig.length - 1 ? 'inactive' : ''}>
              <Link to={!isActive && index === navConfig.length - 1 ? '' : nav.link} onClick={ () => { (handleGtm(nav.name)) }}>
                < nav.icon />
                <span className='label-content'>{nav.label}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </NavWrapStyle>
  )
}
