import styled from 'styled-components'
import tw from 'twin.macro'

export const RegisterTitleWrap = styled.div`
  ${tw`text-center mb-2`}
  
  p {
    @media screen and (min-width: 768px) {
      ${tw`text-5xl font-bold`};
      color: ${({ theme }) => theme.color_title};
    }
  }

  @media screen and (min-width: 768px) {
    ${tw`mx-auto`}
    max-width: 850px;
  }
`
export const RegisterFormWrapStyle = styled.form`
  @media screen and (min-width: 768px) {
    ${tw`mx-auto bg-white rounded-2xl p-10`}
    box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.08);
    max-width: 530px;
  }
`
