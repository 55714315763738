import React from 'react'
import { registerViewModel } from 'viewModels'
import { TextField, CheckboxField, Button, Loading, RegisterTermsModal, RegisterErrorModal } from 'components'
import { RegisterTexts } from 'uiConstants'
import { RegisterFormWrapStyle, RegisterTitleWrap } from './register.styles'

export function Register (): JSX.Element {
  const { isValid, control, handleSubmit, onSubmit, loading, modalTC, handleCloseModal, errors, dynamicName, showModalError, handleCloseModalError } = registerViewModel()

  return (
    <div>
      <RegisterTitleWrap>
        <p>Bienvenido a</p>
        <h5>{dynamicName}</h5>
      </RegisterTitleWrap>
      <p className='text-center mb-6 md:hidden'>{ RegisterTexts.intro }</p>
      {/* eslint-disable-next-line */}
      <RegisterFormWrapStyle onSubmit={handleSubmit(onSubmit)}>
        <p className='text-center mb-6 hidden md:block'>{ RegisterTexts.intro }</p>
        <TextField
          disabled={true}
          name='customer'
          id='customer'
          control={control}
          label={ RegisterTexts.customerLabel }
        />
        <TextField
          disabled={true}
          name='beesCode'
          id='beesCode'
          control={control}
          label={ RegisterTexts.BeesCodeLabel }
        />
        <TextField
          name='name'
          id='name'
          control={control}
          label={ RegisterTexts.nameLabel }
          placeholder={ RegisterTexts.nameLabel }
          errorMessage={ errors?.name?.message }
        />
        <TextField
          name='lastName'
          id='lastName'
          control={control}
          label={ RegisterTexts.lastNameLabel }
          placeholder={ RegisterTexts.lastNameLabel }
          errorMessage={ errors?.lastName?.message }
        />
        <TextField
          name='email'
          id='email'
          control={control}
          label={ RegisterTexts.emailLabel }
          placeholder={ RegisterTexts.emailLabel }
          errorMessage={ errors?.email?.message }
        />
        <TextField
          name='phone'
          id='phone'
          control={control}
          label={ RegisterTexts.phoneLabel }
          placeholder={ RegisterTexts.phoneLabel }
          errorMessage={ errors?.phone?.message }
        />
        <CheckboxField
          name='tc'
          id='tc'
          control={control}
          label={ RegisterTexts.tcLabel }
        />
        <div className='text-center mt-2 md:mt-8'>
          <Button size='large' type='submit' disabled={ !isValid }>
            CONTINUAR
          </Button>
        </div>
      </RegisterFormWrapStyle>
      { loading && <Loading /> }
      <RegisterTermsModal
        showModal={ modalTC }
        handleCloseModal={handleCloseModal}
      />
      <RegisterErrorModal
        handleCloseModal={handleCloseModalError}
        showModal={(showModalError !== '')}
        title={showModalError === 'alreadyRegistered' ? RegisterTexts.alreadyRegisteredTitle : RegisterTexts.errorDynamicTitle}
        description={showModalError === 'alreadyRegistered' ? RegisterTexts.alreadyRegisteredDes : RegisterTexts.errorDynamicDes}
        subTitle={showModalError === 'alreadyRegistered' ? RegisterTexts.alreadyRegisteredSub : RegisterTexts.errorDynamicSub}
      />
    </div>
  )
}
