import { useSelector } from 'react-redux'
import { type ThemeEntity, type DynamicEntity, type BrandEntity, type RankingDateEntity } from 'domain/entities'

interface Dynamic {
  dynamic: DynamicEntity
}

export function dynamicInfoSelector (): DynamicEntity {
  return useSelector((state: Dynamic) => state.dynamic)
}

interface Brand {
  brand: BrandEntity
}

export function brandInfoSelector (): BrandEntity {
  return useSelector((state: Brand) => state.brand)
}

export function brandThemeSelector (): ThemeEntity {
  return useSelector((state: Brand) => state.brand.theme)
}

interface RankingDate {
  rankingDate: RankingDateEntity
}

export function rankingDateSelector (): RankingDateEntity {
  return useSelector((state: RankingDate) => state.rankingDate)
}
