import { useState, useEffect } from 'react'

interface IUseWindowDimensions {
  width?: number
  height?: number
}

function getWindowDimensions (): IUseWindowDimensions {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  } else {
    return {
      width: 0,
      height: 0
    }
  }
}

export function useWindowDimensions (): IUseWindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<IUseWindowDimensions>(getWindowDimensions())

  function handleResize (): void {
    setWindowDimensions(getWindowDimensions())
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return windowDimensions
}
