import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function CloseIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#ffffff' } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill={ primaryColor }
        fillRule='evenodd'
        d='M1.152 1.152a1.2 1.2 0 011.696 0L8 6.303l5.152-5.151a1.2 1.2 0 111.696 1.696L9.697 8l5.151 5.152a1.2 1.2 0 01-1.696 1.696L8 9.697l-5.152 5.151a1.2 1.2 0 01-1.696-1.696L6.303 8 1.152 2.848a1.2 1.2 0 010-1.696z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}
