import React from 'react'
import { Modal, Button } from 'components'
import { LoginErrorModalTexts } from 'uiConstants'
import { WarningDownIcon } from 'assets'
import { type RegisterErrorModalProps } from './registerErrorModal.interfaces'
import { RegisterErrorModalWrap } from './registerErrorModal.styles'

export function RegisterErrorModal (props: RegisterErrorModalProps): JSX.Element {
  const { showModal, handleCloseModal, title, description, subTitle } = props

  return (
    <Modal showModal={ showModal } className='bg-transparent'>
      <RegisterErrorModalWrap>
        <WarningDownIcon className='mx-auto mb-4'/>
        <h5 className='text-black mb-6'>{ title }</h5>
        <p className='text-lg mb-6'>{ description }</p>
        <p className='text-xl font-bold mb-6 md:text-lg'>{ subTitle }</p>
        <Button onAction={ handleCloseModal }>
          { LoginErrorModalTexts.buttonText }
        </Button>
      </RegisterErrorModalWrap>
    </Modal>
  )
}
