import { Routes, Route } from 'react-router-dom'
import React from 'react'
import { Home, Register, Login, LoggedHome, Rewards, Ranking } from 'pages'
import { PublicPath, PrivatePath } from 'uiConstants'
import { useReplaceString } from 'hooks'
import { PrivateRoutes } from './privateRoutes'
import { NavPages } from 'components'

export function AppRoutes (): JSX.Element {
  const dynamicUrl = useReplaceString(PublicPath.dynamicUrl, { ':brand': ':brand', ':dynamic': ':dynamic' })

  return (
    <Routes>
      {/* Public */}
      <Route path={ PublicPath.home } element={<Home /> } />
      <Route path={ `${dynamicUrl}${PublicPath.login}` } element={<Login />} />
      <Route path={ `${dynamicUrl}${PublicPath.register}` } element={<Register />} />
      { /* Private */}
      <Route path={ `${dynamicUrl}${PrivatePath.loggedHome}` } element={
        <PrivateRoutes>
          <>
            <LoggedHome/>
            <NavPages />
          </>
        </PrivateRoutes>
      } />
      <Route path={ `${dynamicUrl}${PrivatePath.rewards}` } element={
        <PrivateRoutes>
          <>
            <Rewards />
            <NavPages />
          </>
        </PrivateRoutes>
      } />
      <Route path={ `${dynamicUrl}${PrivatePath.ranking}` } element={
        <PrivateRoutes>
          <>
            <Ranking/>
            <NavPages />
          </>
        </PrivateRoutes>
      } />
    </Routes>
  )
}
