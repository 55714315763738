type keysType = Record<string, string>

/**
 * @param {string} text string with special words inside [] to replace them
 * @param {keysType} keys object with key and value to be replaced in string
 * @return {string}
*/

export function useReplaceString (text: string, keys: keysType): string {
  const arrayMatch: string[] = Array.from(text.matchAll(/\[([^\][]*)]/g), x => x[1])

  const replace: string = arrayMatch.reduce((previews, current) => {
    return keys[current] !== null ? previews.replace(`[${current}]`, keys[current]) : previews
  }, text)

  return replace
}
