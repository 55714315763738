export enum ActionsTypes {
  EXAMPLE = 'EXAMPLE',
  STORE = 'STORE',
  CLEAR_STORE = 'CLEAR_STORE',
  SESSION = 'SESSION',
  AGEGATE_SAVE = 'AGEGATE_SAVE',
  CLEAR_SESSION = 'CLEAR_SESSION',
  DYNAMIC = 'DYNAMIC',
  CLEAR_DYNAMIC = 'CLEAR_DYNAMIC',
  BRAND = 'BRAND',
  CLEAR_BRAND = 'CLEAR_BRAND',
  RANKING_DATE = 'RANKING_DATE'
}
