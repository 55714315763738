import React from 'react'
import { type IconsProps } from './icons.itenfaces'

export function RewardIcon (props: IconsProps): JSX.Element {
  const { primaryColor = '#000000', className } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      fill='none'
      viewBox='0 0 25 24'
      className={ className }
    >
      <path
        fill={ primaryColor }
        d='M19.196.23v.032H5.77V.229A5.268 5.268 0 00.5 5.501c0 2.848 2.26 5.173 5.075 5.271.295 3.242 2.816 5.828 6.025 6.254v3.176H9.373a.797.797 0 00-.786.786c0 .425-.36.785-.785.785h-.95a.797.797 0 00-.786.786v1.212h13.195v-1.212a.797.797 0 00-.785-.786h-.95a.797.797 0 01-.786-.785.797.797 0 00-.786-.786h-2.357v-3.209c3.143-.491 5.599-3.045 5.894-6.254 2.783-.13 5.009-2.455 5.009-5.271A5.31 5.31 0 0019.196.229zM2.497 5.5a3.234 3.234 0 013.045-3.24v6.515A3.287 3.287 0 012.497 5.5zm17.026 3.242V2.26c1.67.164 2.98 1.54 2.98 3.242a3.324 3.324 0 01-2.98 3.241z'
      ></path>
    </svg>
  )
}
